
<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card-header p-2" style="margin-left: -6px; margin-bottom: 5px;">
                        <ul class="nav nav-pills special">
                            <li v-if="$gate.hasPermission('device_settings_view') && ($gate.isMerchant() || $gate.isImpersonating())"
                                class="nav-item">
                                <a class="nav-link" :class="{ active: activeTab === 'device-settings' }" href="#"
                                    @click.prevent="setActiveTab('device-settings')">Device Preferences</a>
                            </li>
                            <li v-if="$gate.hasPermission('profile_settings_view') && !$gate.isImpersonating()"
                                class="nav-item">
                                <a class="nav-link" :class="{ active: activeTab === 'profile-settings' }" href="#"
                                    @click.prevent="setActiveTab('profile-settings')">Profile Preferences</a>
                            </li>
                            <li v-if="$gate.hasPermission('login_settings_view') && !$gate.isImpersonating() && !$gate.isAzureAD()"
                                class="nav-item">
                                <a class="nav-link" :class="{ active: activeTab === 'login-settings' }" href="#"
                                    @click.prevent="setActiveTab('login-settings')">Login Preferences</a>
                            </li>
                        </ul>
                    </div><!-- /.card-header -->
                    <div class="card">
                        <div class="card-body" style="padding: 20px 10px 10px 10px;">
                            <div class="tab-content">
                                <!-- Setting Tab -->
                                <div class="tab-pane" :class="{ active: activeTab === 'profile-settings' }"
                                    v-show="$gate.hasPermission('profile_settings_view') && !this.$gate.isImpersonating()"
                                    id="profile-settings">
                                    <form class="form-horizontal">
                                        <div class="form-group">
                                            <label for="inputName" class="col-sm-2 control-label">Name</label>

                                            <div class="col-sm-12">
                                                <input type="" v-model="form.name" class="form-control" id="inputName"
                                                    placeholder="Name" :class="{ 'is-invalid': form.errors.has('name') }">
                                                <has-error :form="form" field="name"></has-error>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="inputEmail" class="col-sm-2 control-label">Email</label>

                                            <div class="col-sm-12">
                                                <input type="email" v-model="form.email" class="form-control"
                                                    id="inputEmail" placeholder="Email"
                                                    :class="{ 'is-invalid': form.errors.has('email') }"
                                                    :disabled="$gate.isAzureAD()">
                                                <has-error :form="form" field="email"></has-error>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="col-md-12">
                                                <button type="submit" class="btn btn-primary"
                                                    @click.prevent="updateInfo">Update Profile</button>
                                                <div class="float-right d-sm-inline text-muted">User since:
                                                    <span>{{ form.created_at | myDate }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <!-- Setting Tab -->
                                <div class="tab-pane" :class="{ active: activeTab === 'login-settings' }"
                                    v-show="$gate.hasPermission('login_settings_view') && !this.$gate.isImpersonating()"
                                    id="login-settings">
                                    <!-- Password Policy -->
                                    <form class="form-horizontal">
                                        <div class="row">
                                            <div class="col-md-8" :class="{ 'col-md-12': !showPolicy }">
                                                <div class="form-group">
                                                    <label for="current_password" class="col-sm-12 control-label">Current
                                                        Password</label>

                                                    <div class="col-sm-12">
                                                        <input type="password" v-model="form.current_password"
                                                            class="form-control" id="current_password"
                                                            placeholder="Current Password"
                                                            :class="{ 'is-invalid': form.errors.has('current_password') }">
                                                        <has-error :form="form" field="current_password"></has-error>

                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="new_password" class="col-sm-12 control-label">New
                                                        Password</label>

                                                    <div class="col-sm-12">
                                                        <input type="password" v-model="form.new_password"
                                                            class="form-control" id="new_password"
                                                            placeholder="New Password"
                                                            :class="{ 'is-invalid': form.errors.has('new_password') || !isPasswordValid && form.new_password }"
                                                            @focus="showPolicy = true" @blur="showPolicy = false">
                                                        <has-error :form="form" field="new_password"></has-error>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="confirm_password" class="col-sm-12 control-label">Confirm
                                                        Password</label>

                                                    <div class="col-sm-12">
                                                        <input type="password" v-model="form.confirm_password"
                                                            class="form-control" id="confirm_password"
                                                            placeholder="Confirm Password"
                                                            :class="{ 'is-invalid': form.errors.has('confirm_password') || form.new_password !== form.confirm_password && (form.new_password || form.confirm_password) }"
                                                            @focus="showPolicy = true" @blur="showPolicy = false">
                                                        <has-error :form="form" field="confirm_password"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div class="col-sm-offset-2 col-sm-12">
                                                        <button type="submit" @click.prevent="submitForm"
                                                            class="btn btn-primary">Update
                                                            Password</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 m-0" v-show="showPolicy">
                                                <div id="password-policy" class="password-policy"
                                                    style="box-shadow: none; margin-left: -10px; background-color: transparent;">
                                                    <p>Password must meet the following requirements:</p>
                                                    <ul>
                                                        <li
                                                            :class="{ 'valid': isMinCharsValid, 'invalid': !isMinCharsValid }">
                                                            Minimum 8
                                                            characters</li>
                                                        <li
                                                            :class="{ 'valid': isMaxCharsValid, 'invalid': !isMaxCharsValid }">
                                                            Maximum
                                                            64 characters</li>
                                                        <li
                                                            :class="{ 'valid': isUppercaseValid, 'invalid': !isUppercaseValid }">
                                                            At
                                                            least 1 uppercase letter</li>
                                                        <li
                                                            :class="{ 'valid': isTwoNumbersValid, 'invalid': !isTwoNumbersValid }">
                                                            At
                                                            least 2 consecutive numbers</li>
                                                        <li
                                                            :class="{ 'valid': isTwoSpecialValid, 'invalid': !isTwoSpecialValid }">
                                                            At
                                                            least 2 special characters</li>
                                                        <li
                                                            :class="{ 'valid': isNoSequentialValid, 'invalid': !isNoSequentialValid }">
                                                            No sequential numbers (e.g. 123, 321)</li>
                                                        <li
                                                            :class="{ 'valid': doPasswordsMatch, 'invalid': !doPasswordsMatch }">
                                                            Passwords Match</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>

                                <div class="tab-pane" :class="{ active: activeTab === 'device-settings' }"
                                    v-show="$gate.hasPermission('device_settings_view') && (this.$gate.isMerchant() || this.$gate.isImpersonating())"
                                    id="device-settings">
                                    <form class="form-horizontal">
                                        <div class="form-group" v-if="this.$gate.isCDP()">
                                            <div for="inputCashDiscountRate" class="col-sm-6 control-label" style="padding-bottom: 5px;">
                                                Cash Discount Rate
                                                <span v-tooltip:right="'Discount percentage applied when using tender. Ex. 3.85'">
                                                    <i class="far fa-question-circle"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-12">
                                                <input type="number" v-model="form.cash_discount_rate"
                                                    ref="CashDiscountRate" class="form-control"
                                                    @input="validateAndFormat('cash_discount_rate', 'CashDiscountRate')"
                                                    @keypress="checkKeyPress" id="inputCashDiscountRate"
                                                    placeholder="Cash Discount Rate">
                                                <div v-if="showErrorCashDiscountRate" style="color: red">
                                                    Value must be between 0.00 and 100.00.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div for="inputCreditCardProcessingFees" class="col-sm-6 control-label" style="padding-bottom: 5px;">
                                                Effective Rate
                                                <span
                                                    v-tooltip:right="'This rate should match the percentage of fees charged by your processing company.'">
                                                    <i class="far fa-question-circle"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-12">
                                                <input type="number" v-model="form.cc_processing_fee"
                                                    ref="CreditCardProcessingFees" class="form-control"
                                                    @input="validateAndFormat('cc_processing_fee', 'CreditCardProcessingFees')"
                                                    @keypress="checkKeyPress" id="inputCreditCardProcessingFees"
                                                    placeholder="Credit Card Processing Fee">
                                                <div v-if="showErrorCreditCardProcessingFees" style="color: red">
                                                    Value must be between 0.00 and 100.00.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="inputIndustryType" class="col-sm-6 control-label">
                                                Industry Type
                                            </label>
                                            <div class="col-sm-12">
                                                <select v-model="form.industry_type_id" id="inputIndustryType" class="form-control">
                                                    <option disabled value="">Please select an industry</option>
                                                    <option v-for="industry in industryTypes" :key="industry.id"
                                                    :value="industry.id">
                                                        {{ industry.title }}
                                                    </option>
                                                    <option value="0">Other</option>
                                                </select>
                                            </div>
                                        </div>

                                        <span v-if="this.$gate.isDP()">
                                            <div for="inputPrintDescOnReceipt" class="col-sm-6 control-label" style="padding-bottom: 5px;">
                                                Print Payment Details
                                                <span
                                                    v-tooltip:right="'Print breakdown of Regular & Cash prices on Clover payment receipt.'">
                                                    <i class="far fa-question-circle"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="col-sm-12">
                                                    <label class="switch" style="margin: 0px 0px 20px -15px;">
                                                        <input type="checkbox" id="inputPrintDescOnReceipt"
                                                        v-model="form.print_desc_enabled" v-bind:true-value="1"
                                                            v-bind:false-value="0" data-toggle="toggle" data-on="Yes"
                                                            data-off="No" data-onstyle="success" data-offstyle="danger"
                                                            data-width="100">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <has-error :form="form" field="inputPrintDescOnReceipt"></has-error>
                                            </div>
                                        </span>

                                        <span v-if="(this.$gate.hasPermission('show_tender_options_setting') && this.$gate.isCDP()) || (this.$gate.hasPermission('auto_show_dp_setting') && this.$gate.isDP())">
                                            <label for="inputTenderOptionsEnabled" class="col-sm-6 control-label">
                                                <span v-if="this.$gate.isCDP()">Show Tender Options</span>
                                                <span v-if="this.$gate.isDP()">Automatically Show Dual Pricing</span>
                                                <span v-if="this.$gate.isCDP()" v-tooltip:right="'Shows popup screen for tender selection on checkout.'">
                                                    <i class="far fa-question-circle"></i>
                                                </span>
                                                <span v-if="this.$gate.isDP()" v-tooltip:right="'Automatically show Dual Pricing Screen after clicking Pay.'">
                                                    <i class="far fa-question-circle"></i>
                                                </span>
                                            </label>

                                            <div class="col-sm-12">
                                                <div class="col-sm-12">
                                                    <label class="switch" style="margin: 0px 0px 20px -15px;">
                                                        <input type="checkbox" id="tenderOptionsEnabled"
                                                            v-model="form.tender_options_enabled" v-bind:true-value="1"
                                                            v-bind:false-value="0" data-toggle="toggle" data-on="Yes"
                                                            data-off="No" data-onstyle="success" data-offstyle="danger"
                                                            data-width="100">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <has-error :form="form" field="inputTenderOptionsEnabled"></has-error>
                                            </div>
                                        </span>
                                        
                                        <span v-if="this.$gate.isDP()">
                                            <div for="inputCustomerFacingDisplayEnabled" class="col-sm-6 control-label" style="padding-bottom: 5px;">
                                                Customer Facing Display
                                                <span
                                                    v-tooltip:right="'Use this setting to have your customers select their own payment type.'">
                                                    <i class="far fa-question-circle"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="col-sm-12">
                                                    <label class="switch" style="margin: 0px 0px 20px -15px;">
                                                        <input type="checkbox" id="inputCustomerFacingDisplayEnabled"
                                                        v-model="form.cf_screen_enabled" v-bind:true-value="1"
                                                            v-bind:false-value="0" data-toggle="toggle" data-on="Yes"
                                                            data-off="No" data-onstyle="success" data-offstyle="danger"
                                                            data-width="100">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <has-error :form="form" field="inputCustomerFacingDisplayEnabled"></has-error>
                                            </div>
                                        </span>

                                        <div class="form-group">
                                            <div class="col-md-12">
                                                <button type="submit" class="btn btn-primary" @click.prevent="updateInfo"
                                                    :disabled="showErrorCashDiscountRate || showErrorCreditCardProcessingFees">Update Device
                                                    Settings</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <!-- /.tab-pane -->
                            </div>
                            <!-- /.tab-content -->
                            <div v-if="syncing" class="loading-screen"
                                style="position: fixed !important; height: 100vh !important; top: 0 !important;">
                                <div class="loading-spinner"></div>
                                <div class="loading-text">Syncing orders...</div>
                            </div>
                        </div><!-- /.card-body -->
                    </div>
                    <!-- /.nav-tabs-custom -->
                </div>
                <!-- end tabs -->
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            activeTab: '',
            form: new Form({
                id: '',
                name: '',
                email: '',
                password: '',
                created_at: '',
                cash_discount_rate: null,
                cc_processing_fee: null,
                print_desc_enabled: null,
                tender_options_enabled: null,
                cf_screen_enabled: null,
                new_password: "",    // initialize to an empty string
                confirm_password: "", // initialize to an empty string
                industry_type_id: "",
            }),
            previousCCProcessingFee: null,
            showPolicy: false,
            showErrorCashDiscountRate: false,
            showErrorCreditCardProcessingFees: false,
            syncing: false,
            industryTypes: [],
        }
    },
    mounted() {
        if (this.$gate.hasPermission('device_settings_view') && (this.$gate.isMerchant() || this.$gate.isImpersonating())) {
            this.activeTab = 'device-settings';
        } else if (this.$gate.hasPermission('profile_settings_view') && !this.$gate.isImpersonating()) {
            this.activeTab = 'profile-settings';
        } else if (this.$gate.hasPermission('login_settings_view') && !this.$gate.isImpersonating()) {
            this.activeTab = 'login-settings';
        }
    },
    created() {
        this.getIndustryTypes();
        this.$Progress.start();
        axios.get("api/profile")
            .then(({ data }) => {
                this.form.fill(data.data);
                if (this.$gate.isMerchant() || this.$gate.isImpersonating()) {
                    this.previousCCProcessingFee = data.data.cc_processing_fee;
                    this.form.industry_type_id = data.data.industry_type_id;
                }
            });
        this.$Progress.finish();
    },
    computed: {
        isMinCharsValid() {
            return this.form.new_password && this.form.new_password.length >= 8;
        },

        isMaxCharsValid() {
            return this.form.new_password && this.form.new_password.length <= 64;
        },


        isUppercaseValid() {
            return /[A-Z]/.test(this.form.new_password);
        },

        isTwoNumbersValid() {
            return this.form.new_password && (this.form.new_password.match(/\d/g) || []).length >= 2;
        },

        isTwoSpecialValid() {
            return this.form.new_password && (this.form.new_password.match(/[@$!%*#?&]/g) || []).length >= 2;
        },

        isNoSequentialValid() {
            return !(/(012|123|234|345|456|567|678|789|987|876|765|654|543|432|321|210)/.test(this.form.new_password));
        },

        doPasswordsMatch() {
            return this.form.new_password && this.form.new_password === this.form.confirm_password;
        },

        isPasswordValid() {
            return this.isMinCharsValid && this.isMaxCharsValid && this.isUppercaseValid && this.isTwoNumbersValid && this.isTwoSpecialValid && this.isNoSequentialValid && this.doPasswordsMatch;
        },
    },
    methods: {
        getIndustryTypes() {
            this.$Progress.start();
            axios.get("api/boarding/industry-types")
                .then(({ data }) => {
                    this.industryTypes = data.data;
                });
            this.$Progress.finish();
        },
        setActiveTab(tabName) {
            this.activeTab = tabName;
        },
        updateInfoWithDelay() {
            setTimeout(() => {
                this.updateInfo();
            }, 100);
        },
        updateInfo() {
            this.$Progress.start();
            if (this.form.password == '') {
                this.form.password = undefined;
            }
            if (this.$gate.isMerchant() || this.$gate.isImpersonating()) {
                if (this.previousCCProcessingFee != this.form.cc_processing_fee) {
                    this.syncing = true;
                }
            }
            this.form.put('api/profile')
                .then((data) => {
                    this.$Progress.finish();
                    this.syncing = false;
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    if (window.isCloverDevice && window.AndroidInterface) {
                        const responseJson = JSON.stringify({
                            cash_discount_rate: this.form.cash_discount_rate,
                            cc_processing_fee: this.form.cc_processing_fee,
                            print_desc_enabled: this.form.print_desc_enabled,
                            tender_options_enabled: this.form.tender_options_enabled,
                            cf_screen_enabled: this.form.cf_screen_enabled,
                        });
                        window.AndroidInterface.loadDeviceSettings(responseJson);
                    }
                })
                .catch((data) => {
                    this.$Progress.fail();

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });

                    if (window.isCloverDevice) {
                        window.AndroidInterface.loadDeviceSettings('Error event triggered from Vue.js');
                    }

                });
        },
        submitForm() {
            if (!this.isPasswordValid) {
                Toast.fire({
                    icon: 'error',
                    title: 'Password does not meet the requirements!'
                });
                return;
            }
            this.updatePassword();
        },
        updatePassword() {
            this.$Progress.start();
            this.form.post('api/change-password')
                .then((data) => {
                    //  Fire.$emit('AfterCreate');
                    this.$Progress.finish();
                    this.form.current_password = '';
                    this.form.new_password = '';
                    this.form.confirm_password = '';

                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                })
                .catch(() => {
                    this.$Progress.fail();

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                });
        },
        setCursorToEnd(el) {
            if (typeof el.selectionStart === "number") {
                el.selectionStart = el.selectionEnd = el.value.length;
            } else if (typeof el.createTextRange !== "undefined") {
                el.focus();
                var range = el.createTextRange();
                range.collapse(false);
                range.select();
            }
        },
        validateAndFormat(formField, inputRef) {
            if (!window.isCloverDevice && !window.AndroidInterface) {
                this.formatNum(formField, inputRef);
            }

            let num = parseFloat(this.form[formField]);
            if (window.isCloverDevice || window.AndroidInterface) {
                let decimalPart = this.form[formField].split('.')[1];
                if(decimalPart && decimalPart.length > 2) {
                    this.form[formField] = this.form[formField].slice(0, -1);
                }
            }
            if (isNaN(num) || num < 0.00 || num > 100) {
                this['showError' + inputRef] = true;
            } else {
                this['showError' + inputRef] = false;
            }
            this.$nextTick(() => {
                this.setCursorToEnd(this.$refs[inputRef]);
            });
        },
        formatNum(formField, inputRef) {
            var num = this.form[formField].replace(/\D/g, '');

            // Ensure the number isn't too large 
            num = num.length > 5 ? num.slice(0, 5) : num;

            // Pad the number with leading zeros if it's too short 
            while (num.length < 3) {
                num = '0' + num;
            }

            // Insert the decimal point 
            num = num.slice(0, num.length - 2) + '.' + num.slice(num.length - 2);

            // Remove leading zeros (but ensure there's always one before the decimal point) 
            num = num.replace(/^0+([1-9])/, '$1').replace(/^0+\./, '0.');

            this.form[formField] = num;
            this.$nextTick(() => {
                this.setCursorToEnd(this.$refs[inputRef]);
            });
        },
        checkKeyPress(event) {
            const keyCode = event.keyCode ? event.keyCode : event.which;
            const keyValue = String.fromCharCode(keyCode);
            if (["e", "+", "-"].includes(keyValue.toLowerCase())) {
                event.preventDefault();
            }
        }
    }
}
</script>
