<template>
    <section class="content">
        <div v-if="(($gate.isAdmin() || $gate.isSupport()) && !$gate.isImpersonating())">
            <not-found></not-found>
        </div>
        <div class="container-fluid">
            <div class="row" v-if="$gate.hasPermission('dashboard_view_totals')">
                <div class="col-12 col-sm-6 col-md-4">
                    <div class="info-box">
                        <div class="info-box-content">
                            <span class="info-box-text">Today's Cash Sales</span>
                            <span v-if="!totalsLoading" class="info-box-number">
                                ${{ totals.cash }}
                            </span>
                            <span v-if="totalsLoading" class="info-box-number">
                                $0.00
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-4">
                    <div class="info-box mb-3">
                        <div class="info-box-content">
                            <span class="info-box-text">Today's Card Sales</span>
                            <span v-if="!totalsLoading" class="info-box-number">
                                ${{ totals.card }}
                            </span>
                            <span v-if="totalsLoading" class="info-box-number">
                                $0.00
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->

                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>

                <div class="col-12 col-sm-6 col-md-4">
                    <div class="info-box mb-3">
                        <div class="info-box-content">
                            <span class="info-box-text">Total Fees Paid</span>
                            <span style="position: absolute; right: 20px; top: 10px; font-size: 1.5em;"
                                v-tooltip:right="'This amount represents the fees incurred, calculated based on the Effective Rate configured in your settings. It displays the total sum paid or saved through your use of TCD.'">
                                <i class="far fa-question-circle"></i>
                            </span>
                            <span v-if="!totalsLoading" class="info-box-number">
                                ${{ totals.fees }}
                            </span>
                            <span v-if="totalsLoading" class="info-box-number">
                                $0.00
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="row">
                <!-- Left col -->
                <div class="col-md-12">
                    <!-- MAP & BOX PANE -->
                    <div class="">
                        <div class="card-header">
                            <h3 class="card-title">Orders</h3>
                            <div class="card-tools card-tools-container" v-if="!syncing">
                                <input type="text" name="search" class="form-control" placeholder="Search"
                                    v-model="searchQuery" @input="searchOrders" />
                                <div class="card-tools-buttons">
                                    <button v-if="$gate.hasPermission('orders_force_sync')" type="button"
                                        class="btn btn-sm btn-primary" @click="syncOrders">
                                        <i class="fa fa-sync"></i>
                                        Sync Orders
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table m-0 table-hover">
                                    <thead>
                                        <tr>
                                            <th>Order</th>
                                            <th>Order Total</th>
                                            <th>Cash Discount</th>
                                            <th>Additional Discounts</th>
                                            <th>Tender Type</th>
                                            <th>Fees Paid</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="order in orders" :key="order.id" @click="openOrder(order.order_id)">
                                            <td data-label="Order" class="sm-first-td">
                                                <p style="margin: 0;">{{ formatDateTime(order.order_time) }}</p>
                                                <p style="margin: 0; color: #747474;">{{ order.order_id }} <br> {{
                                                    order.order_state.toUpperCase() }} <i
                                                        class="fas fa-exclamation-triangle text-danger"
                                                        v-if="order.discount_amount != 0 && order.tender_type.trim() == 'Credit Card'"
                                                        v-tooltip:right="'Cash Discount applied to a card transaction!'"></i>
                                                </p>
                                            </td>
                                            <td data-label="Order Total">
                                                ${{ order.orderAmount }}
                                            </td>
                                            <td data-label="Cash Discount">
                                                ${{ order.discount_amount }}
                                            </td>
                                            <td data-label="Additional Discounts">
                                                {{ order.discount_name }}
                                            </td>
                                            <td data-label="Tender Type">
                                                {{ order.tender_type }}
                                            </td>
                                            <td data-label="Fees Paid">
                                                ${{ order.feesPaid }}
                                            </td>
                                        </tr>
                                        <div style="margin: 10px;" v-if="loading">Loading...</div>
                                        <div class="syncing-screen" v-if="syncing && $gate.isImpersonating()">
                                            <div class="loading-spinner"></div>
                                            <div class="loading-text">Syncing items...</div>
                                        </div>
                                        <div style="margin: 10px;" v-if="!loading && orders.length === 0 && !syncing">No
                                            orders found.
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                            </div>
                            <div v-if="syncing && !$gate.isImpersonating()" class="loading-screen">
                                <div class="loading-spinner"></div>
                                <div class="loading-text">Syncing orders...</div>
                            </div>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                    <!-- /.card -->
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div><!--/. container-fluid -->
    </section>
</template>
<script>
import Swal from 'sweetalert2';
function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}
export default {
    data() {
        return {
            editmode: false,
            orders: [],
            page: 1,
            lastLoadedOffset: 0,
            loading: false,
            syncing: false,
            searchQuery: "",
            totals: {
                cash: 0,
                card: 0,
                fees: 0,
            },
            totalsLoading: false,
            cloverBaseUrl: window.appConfig.cloverBaseUrl,
            merchantId: window.user.clover_merchant_id,
        };
    },
    created() {
        this.getCashTotal();
        this.loadOrders();
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        formatDateTime(value) {
            if (!value) return '';
            const date = new Date(value);

            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            const hours = date.getHours();
            const minutes = ('0' + date.getMinutes()).slice(-2);
            const amPm = hours < 12 ? 'am' : 'pm';
            const hours12 = hours % 12 || 12;

            return `${month}-${day}-${year} at ${hours12}:${minutes}${amPm}`;
        },
        handleScroll() {
            const scrollY = window.scrollY + 1;
            const visibleHeight = window.innerHeight;
            const pageHeight = document.documentElement.scrollHeight;
            const offset = this.orders.length;

            if (scrollY + visibleHeight >= pageHeight && !this.loading && offset !== this.lastLoadedOffset) {
                this.lastLoadedOffset = offset;
                this.getResults(this.searchQuery);
                console.log("Loading more orders...");
            }
        },
        getResults(searchQuery) {
            this.$Progress.start();
            this.loading = true;
            if (this.$gate.hasPermission('products_view') && !this.syncing) {
                axios
                    .get('api/orders', {
                        params: {
                            offset: this.orders.length,
                            search: searchQuery,
                        }
                    })
                    .then(({ data }) => {
                        const updatedOrders = data.data.map(order => {
                            return {
                                ...order,
                                orderAmount: (order.order_amount / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                feesPaid: (order.fees_paid / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                discount_amount: (order.discount_amount / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                            };
                        });
                        this.orders = [...this.orders, ...updatedOrders];
                        this.loading = false;
                    });
                this.$Progress.finish();
            } else {
                this.$Progress.fail();
                this.loading = false;
            }
        },
        getCashTotal() {
            this.totalsLoading = true;
            axios
                .get('api/clover-merchants/totals')
                .then(({ data }) => {
                    // this.cashTotal = data.data;
                    this.totals.cash = (data.data.cash_total / 100).toFixed(2);
                    this.totals.cash = this.totals.cash.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    this.totals.card = (data.data.card_total / 100).toFixed(2);
                    this.totals.card = this.totals.card.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    this.totals.fees = (data.data.fees_total / 100).toFixed(2);
                    this.totals.fees = this.totals.fees.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    this.totalsLoading = false;
                });
        },
        loadOrders(searchQuery) {
            this.getResults(searchQuery);
        },
        searchOrders: debounce(function () {
            this.orders = [];
            this.lastLoadedOffset = 0;
            this.loadOrders(this.searchQuery);
        }, 300),
        syncOrders() {
            this.$Progress.start();
            Swal.fire({
                title: 'Sync Orders',
                text: 'Are you sure you want to sync orders? This may a few minutes.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, sync orders',
                cancelButtonText: 'Cancel'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.orders = [];
                    this.lastLoadedOffset = 0;
                    this.syncing = true;
                    // If user confirmed, make the API request
                    axios.get('api/orders/sync-all-clover-orders-to-db')
                        .then((response) => {
                            if (response.data.status === 1) {
                                Swal.fire({
                                    title: 'Sync Successful',
                                    text: 'All orders have been synced',
                                    icon: 'success'
                                });
                                this.syncing = false;
                            } else {
                                Swal.fire({
                                    title: 'Sync Failed',
                                    text: 'There was an error syncing orders',
                                    icon: 'error'
                                });
                                this.syncing = false;
                            }
                            this.loading = false;
                            this.getCashTotal();
                            this.loadOrders();
                        })
                        .catch((error) => {
                            console.error(error);
                            Swal.fire({
                                title: 'Sync Failed',
                                text: 'There was an error syncing orders',
                                icon: 'error'
                            });
                            this.syncing = false;
                            this.getCashTotal();
                            this.loadOrders();
                            this.loading = false;
                        });
                }
            });
            this.$Progress.finish();
        },
        openOrder(orderId) {
            if (window.isCloverDevice && window.AndroidInterface) {
                try {
                    window.AndroidInterface.openOrder(orderId);
                } catch (error) {
                    Toast.fire({
                        icon: 'error',
                        title: 'There was an issue when trying to open order. Please try again later.'
                    });
                }
            } else if (!this.$gate.isImpersonating()) {
                window.open(this.cloverBaseUrl + '/orders/m/' + this.merchantId + '/orders/' + orderId, '_blank');
            }
        },
    }
}
</script>