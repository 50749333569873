<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div v-if="!$gate.hasPermission('migration_tool_use')">
                        <not-found></not-found>
                    </div>
                    <div class="card" v-if="$gate.hasPermission('migration_tool_use')">
                        <div class="card-body table-responsive p-3" style="overflow: visible !important;">
                            <h2>
                                True Cash Discount Migration Tool
                            </h2>
                            <p>
                                Use the steps below to migrate a merchant from the old TCD app to the new version.
                            </p>
                            <br>

                            <h4><strong>Step 1: Pre-migration preparation</strong></h4>
                            <p>Before proceeding, review the guide that explains how to ready a merchant for the migration.
                            </p>
                            <a href="/documents/migration-guide.pdf" target="_blank">
                                <button class="btn btn-sm btn-info"
                                    style="color: white; padding: 10px; margin-bottom: 20px;">View Guide</button>
                            </a>

                            <br>

                            <h4><strong>Step 2: Verification</strong></h4>
                            <p>Confirm the following prerequisites have been met:</p>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="checkbox3"
                                            v-model="confirmSyncItems">
                                        <label class="form-check-label" for="checkbox3">
                                            The merchant has completely synced their items using the old TCD app.
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="checkbox1"
                                            v-model="confirmAppInstall">
                                        <label class="form-check-label" for="checkbox1">
                                            The merchant has both the old and new TCD apps installed on their Clover device.
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="checkbox2"
                                            v-model="confirmOpenNewApp">
                                        <label class="form-check-label" for="checkbox2">
                                            The merchant has launched the new TCD app at least once and hasn't completed the
                                            onboarding process.
                                            <span
                                                v-tooltip:right="'To verify, navigate to Merchants and search for the merchant. If they appear with a status of Boarding, this box can be checked.'">
                                                <i class="far fa-question-circle"></i>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <p class="mt-3">
                                Once the above checks are confirmed, you can proceed to the final step.
                            </p>

                            <h4><strong>Step 3: Data Migration</strong></h4>
                            <p>Search for and select the correct merchant to migrate using the field below. Ensure you've
                                chosen the right merchant and have followed the previous steps before moving forward.</p>

                            <!-- The search/select feature for merchants -->
                            <div class="col-lg-4 col-md-10 col-sm-12" style="margin-left: -15px;">
                                <multiselect :options="merchants" :multiple="false" :close-on-select="true"
                                    :clear-on-select="false" :preserve-search="true" placeholder="Select Merchant"
                                    label="name" property track-by="merchant_id" v-model="selectedMerchant"
                                    @input="value => { searchQuery = value; }" @search-change="handleSearchChange"
                                    :preselect-first="false" required>
                                    <template slot="noOptions">
                                        Start typing to find merchant
                                    </template>
                                    <template slot="noResult">
                                        Merchant not found.
                                    </template>
                                    <template slot="singleLabel" slot-scope="{ option }">
                                        {{ option.name }} - {{ option.merchant_id }}
                                    </template>
                                </multiselect>
                            </div>
                            <p style="font-style: italic; margin-top: 10px;">Note: If you are not able to find the merchant,
                                please ensure all steps have been completed prior to continuing to this step. If all steps
                                are complete, try refreshing the page and trying again.</p>
                            <div class="col-md-12">
                                <button type="button" class="btn btn-block col-md-3"
                                    :class="allDataValid ? 'btn-primary' : 'btn-secondary'" :disabled="!allDataValid"
                                    @click="migrationConfirmation" style="padding: 10px; margin: 20px auto 10px auto;">
                                    Migrate
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="isMigrating || isSyncingModifiers || isSyncingProducts" class="loading-screen">
                        <div class="loading-spinner"></div>
                        <div v-if="isMigrating" class="loading-text">Migrating Merchant...<br>DO NOT LEAVE THIS PAGE OR
                            REFRESH!</div>
                        <div v-if="isSyncingModifiers" class="loading-text">Syncing Modifiers...<br>DO NOT LEAVE THIS PAGE
                            OR REFRESH!</div>
                        <div v-if="isSyncingProducts" class="loading-text">Syncing Products...<br>DO NOT LEAVE THIS PAGE OR
                            REFRESH!</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}
export default {
    components: {
        Multiselect
    },
    watch: {
        // Watch the searchQuery and call our API method after a debounce
        searchQuery: function (newVal) {
            this.debouncedFetchMerchants(newVal);
        }
    },
    data() {
        return {
            confirmAppInstall: false,
            confirmOpenNewApp: false,
            confirmSyncItems: false,
            merchants: [],
            searchQuery: "",
            selectedMerchant: null,
            isMigrating: false,
            isSyncingProducts: false,
            isSyncingModifiers: false,
        };
    },
    created() {
        this.debouncedFetchMerchants = debounce(this.fetchMerchants, 150);
    },
    mounted() {
    },
    computed: {
        allDataValid() {
            return (
                this.confirmAppInstall &&
                this.confirmOpenNewApp &&
                this.confirmSyncItems &&
                this.selectedMerchant != null
            );
        },
    },
    methods: {
        fetchMerchants(query) {
            if (query.length >= 3) {
                axios.get("api/migrate", { params: { search: query } })
                    .then(({ data }) => {
                        this.merchants = data.data;
                    });
            }
        },
        handleSearchChange(value) {
            this.searchQuery = value;
        },
        migrationConfirmation() {
            if (!this.allDataValid) {
                Swal.fire({
                    title: "Error!",
                    text: "Please ensure all steps have been completed before migrating.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                return;
            }
            Swal.fire({
                title: 'Migrate Merchant?',
                text: 'Are you sure you want to migrate this merchant? (' + this.selectedMerchant.name + ') This cannot be undone! Please ensure you have followed all steps before proceeding.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, migrate!',
                cancelButtonText: 'Cancel',
                customClass: {
                    confirmButton: 'swal2-confirm-red',
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.initializeMigration();
                }
            });
        },
        initializeMigration() {
            this.isMigrating = true;
            axios.post("api/migrate", { merchant_id: this.selectedMerchant.merchant_id })
                .then(({ data }) => {
                    if (data.data.status === 1) {
                        this.isMigrating = false;
                        this.isSyncingProducts = true;
                        this.syncProducts();
                    } else {
                        this.isMigrating = false;
                        this.showMigrateError('Migration Failed', data.message);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.isMigrating = false;
                    this.showMigrateError('Migration Failed', 'An error occurred while migrating the merchant. Please try again.');
                });
        },
        syncProducts() {
            this.$Progress.start();
            axios.get('api/products/sync-all-clover-items-to-db', { params: { merchant_id: this.selectedMerchant.merchant_id } })
                .then((response) => {
                    if (response.data.status === 1) {
                        this.isSyncingProducts = false;
                        this.isSyncingModifiers = true;
                        this.syncModifiers();
                    } else {
                        this.isSyncingProducts = false;
                        this.showMigrateError('Migration Completed with Errors', 'The merchant has been migrated, but there were errors syncing products. Please review the merchant\'s products and modifiers and manually sync if any are missing.');
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.isSyncingProducts = false;
                    this.showMigrateError('Migration Completed with Errors', 'The merchant has been migrated, but there were errors syncing products. Please review the merchant\'s products and modifiers and manually sync if any are missing.');
                });

            this.$Progress.finish();
        },
        syncModifiers() {
            this.$Progress.start();
            axios.get('api/modifier-groups/sync-all-clover-modifier-groups-to-db', { params: { merchant_id: this.selectedMerchant.merchant_id } })
                .then((response) => {
                    if (response.data.status === 1) {
                        this.isSyncingModifiers = false;
                        this.showMigrateSuccess('Migration Successful', 'The merchant has been migrated successfully.');
                    } else {
                        this.isSyncingModifiers = false;
                        this.showMigrateError('Migration Completed with Errors', 'The merchant has been migrated, but there were errors syncing modifiers. Please review the merchant\'s modifiers and manually sync if any are missing.');
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.isSyncingModifiers = false;
                    this.showMigrateError('Migration Completed with Errors', 'The merchant has been migrated, but there were errors syncing modifiers. Please review the merchant\'s modifiers and manually sync if any are missing.');
                });

            this.$Progress.finish();
        },
        showMigrateSuccess(title, text) {
            if (!this.$gate.hasPermission('merchants_impersonate')) {
                Swal.fire({
                    title: title,
                    text: text,
                    icon: 'success',
                    confirmButtonText: "Continue",
                }).then(() => {
                    window.location.reload();
                });
            } else {
                Swal.fire({
                    title: title,
                    text: text,
                    icon: 'success',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: "Continue",
                    cancelButtonText: "Impersonate"
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    } else {
                        this.impersonateMerchant(this.selectedMerchant.merchant_id);
                    }
                });
            }

        },
        showMigrateError(title, text) {
            Swal.fire({
                title: title,
                text: text,
                icon: 'error',
                confirmButtonText: "OK"
            }).then(() => {
                window.location.reload();
            });
        },
        impersonateMerchant(merchantId) {
            this.$Progress.start();
            axios
                .post('api/clover-merchants/impersonate/' + merchantId)
                .then(({ data }) => {
                    this.$Progress.finish();
                    window.location.href = '/dashboard';
                })
                .catch(error => {
                    this.$Progress.fail();
                    Swal.fire({
                        title: 'Error!',
                        text: error.response.data.message,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                });
        },
    }
};
</script>
  