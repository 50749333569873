<style lang="scss">
@import '../../../../public/css/custom.css';
</style>
<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12">
                    <div v-if="!$gate.hasPermission('merchants_view')">
                        <not-found></not-found>
                    </div>
                    <div class="" v-if="$gate.hasPermission('merchants_view')">
                        <div class="card-header">
                            <h3 class="card-title">Merchants</h3>

                            <div class="card-tools card-tools-container">
                                <input type="text" name="search" class="form-control" placeholder="Search"
                                    v-model="searchQuery" @input="searchMerchants" />
                                <div class="card-tools-buttons">
                                    <button type="button" v-if="$gate.hasPermission('merchants_add')"
                                        class="btn btn-sm btn-primary" @click="addMerchant()">
                                        <i class="fa fa-plus-square"></i>
                                        Add New
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Clover Merchant ID</th>
                                        <th>Business Name</th>
                                        <th>Tier</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="merchant in merchants" :key="merchant.id"
                                        :class="{ 'is-inactive': merchant.is_active === 0 || merchant.invalid_uninstall === 1 }">
                                        <td data-label="Clover Merchant ID">{{ merchant.merchant_id }}</td>
                                        <td data-label="Business Name">{{ merchant.name }}</td>
                                        <td data-label="Tier">{{ merchant.tier }}</td>
                                        <td data-label="Email">{{ merchant.email }}</td>
                                        <td data-label="Status">
                                            {{ getStatus(merchant) }}
                                        </td>
                                        <td data-label="Action">
                                            <div class="btn-group">
                                                <button type="button" class="btn btn-sm btn-primary dropdown-toggle"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Actions
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a v-if="$gate.hasPermission('merchants_impersonate') && merchant.is_active === 1 && merchant.invalid_uninstall === 0"
                                                        class="dropdown-item" href="#"
                                                        @click="impersonateMerchant(merchant.merchant_id)"><i
                                                            class="fa fa-user-secret"></i> Impersonate</a>
                                                    <a v-if="$gate.hasPermission('merchants_reactivate') && merchant.is_active === 0 && merchant.invalid_uninstall === 0"
                                                        class="dropdown-item" href="#"
                                                        @click="reactivateMerchant(merchant.merchant_id)"><i
                                                            class="fas fa-plug"></i> Reactivate</a>
                                                    <a class="dropdown-item" href="#" @click="viewMerchant(merchant)"><i
                                                            class="fa fa-eye"></i> View</a>
                                                    <a v-if="$gate.hasPermission('merchants_force_boarding') && merchant.boarding_step == 0"
                                                        class="dropdown-item" href="#"
                                                        @click="handleBoarding(merchant, 'force')"><i
                                                            class="fas fa-clipboard-list"></i> Force Boarding</a>
                                                    <a v-if="$gate.hasPermission('merchants_bypass_boarding') && merchant.boarding_step != 0"
                                                        class="dropdown-item" href="#"
                                                        @click="handleBoarding(merchant, 'bypass')"><i
                                                            class="fas fa-clipboard-check"></i> Bypass Boarding</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <div style="margin: 10px;" v-if="loading">Loading...</div>
                                    <div style="margin: 10px;" v-if="!loading && merchants.length === 0">No merchants found.
                                    </div>
                                    <div v-if="reactivatingMerchant" class="loading-screen"
                                        style="position: fixed !important; height: 100vh !important; top: 0 !important;">
                                        <div class="loading-spinner"></div>
                                        <div class="loading-text">Reactivating Merchant...<br>DO NOT leave this page!</div>
                                    </div>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="merchantModal" tabindex="-1" role="dialog" aria-labelledby="merchantModal"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="modal-title">
                                <h4 v-if="viewMode">Merchant Details</h4>
                            </div>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <label for="merchant_id" class="col-md-4 col-form-label">Merchant ID:</label>
                            <div class="col-md-12">
                                <input v-if="viewMode" v-model="selectedMerchant.merchant_id" id="merchant_id" type="text"
                                    class="form-control" disabled>
                            </div>
                            <label v-if="viewMode" for="merchant_name" class="col-md-4 col-form-label">Business
                                Name:</label>
                            <div class="col-md-12" v-if="viewMode">
                                <input v-model="selectedMerchant.name" id="merchant_name" type="text" class="form-control"
                                    disabled>
                            </div>
                            <label v-if="viewMode" for="merchant_industry" class="col-md-4 col-form-label">Industry:</label>
                            <div class="col-md-12" v-if="viewMode">
                                <input v-model="selectedMerchantIndustryName" id="merchant_name" type="text"
                                    class="form-control" disabled>
                            </div>
                            <label v-if="viewMode" for="merchant_tier" class="col-md-4 col-form-label">Tier:</label>
                            <div class="col-md-12" v-if="viewMode">
                                <input v-model="selectedMerchant.tier" id="merchant_tier" type="text"
                                    class="form-control" disabled>
                            </div>
                            <label v-if="viewMode" for="merchant_phone" class="col-md-4 col-form-label">Phone
                                Number:</label>
                            <div class="col-md-12" v-if="viewMode">
                                <input v-model="selectedMerchant.phone_number" id="merchant_phone" type="phone"
                                    class="form-control" placeholder="None" disabled>
                            </div>
                            <label v-if="viewMode" for="merchant_email" class="col-md-4 col-form-label">Email:</label>
                            <div class="col-md-12" v-if="viewMode">
                                <input v-model="selectedMerchant.email" id="merchant_email" type="email"
                                    class="form-control" disabled>
                            </div>
                            <label v-if="viewMode" for="merchant_timezone" class="col-md-4 col-form-label">Timezone:</label>
                            <div class="col-md-12" v-if="viewMode">
                                <input v-model="selectedMerchant.timezone" id="merchant_timezone" type="text"
                                    class="form-control" disabled>
                            </div>
                            <label v-if="viewMode" for="merchant_since" class="col-md-4 col-form-label">Merchant
                                Since:</label>
                            <div class="col-md-12" v-if="viewMode">
                                <input v-model="selectedMerchant.merchant_since" id="merchant_since" type="text"
                                    class="form-control" disabled>
                            </div>
                            <label v-if="viewMode && getStatus(selectedMerchant) != 'Active'" for="uninstall_date"
                                class="col-md-4 col-form-label">Uninstall Date:</label>
                            <div class="col-md-12" v-if="viewMode && getStatus(selectedMerchant) != 'Active'">
                                <input v-model="selectedMerchant.uninstall_date" id="uninstall_date" type="text"
                                    class="form-control" disabled>
                            </div>
                            <br>
                            <div class="col-md-12">
                                <button class="btn btn-md btn-secondary" data-dismiss="modal"
                                    aria-label="Close">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import Swal from 'sweetalert2';
import moment from 'moment';
function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}
export default {
    data() {
        return {
            cloverAppId: window.appConfig.cloverAppId,
            appUrl: window.appConfig.appUrl,
            cloverOAuthUrl: window.appConfig.cloverOAuthUrl,
            viewMode: false,
            merchants: [],
            selectedMerchant: '',
            newMerchantId: '',
            lastLoadedOffset: 0,
            loading: false,
            page: 1,
            searchQuery: "",
            reactivatingMerchant: false,
            industryTypes: []
        };
    },
    created() {
        this.loadmerchants();
        this.getIndustryTypes();
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    computed: {
        selectedMerchantIndustryName() {
            if (this.selectedMerchant.industry_type_id == 0) {
                return 'Other';
            }
            return this.industryTypes[this.selectedMerchant.industry_type_id] || 'Unknown';
        }
    },
    methods: {
        handleScroll() {
            const scrollY = window.scrollY + 1;
            const visibleHeight = window.innerHeight;
            const pageHeight = document.documentElement.scrollHeight;
            const offset = this.merchants.length;

            if (scrollY + visibleHeight >= pageHeight && !this.loading && offset !== this.lastLoadedOffset) {
                this.lastLoadedOffset = offset;
                this.getResults(this.searchQuery);
            }
        },
        getIndustryTypes() {
            this.$Progress.start();
            axios.get("api/boarding/industry-types")
                .then(({ data }) => {
                    this.industryTypes = data.data.reduce((acc, item) => {
                        acc[item.id] = item.title;
                        return acc;
                    }, {});
                })
                .finally(() => {
                    this.$Progress.finish();
                });
        },
        getResults(searchQuery) {
            this.$Progress.start();
            this.loading = true;
            if (this.$gate.hasPermission('merchants_view')) {
                axios
                    .get('api/clover-merchants', {
                        params: {
                            offset: this.merchants.length,
                            search: searchQuery,
                        }
                    })
                    .then(({ data }) => {
                        this.merchants = this.merchants.concat(data.data);
                        this.loading = false;
                    });
            }
            this.$Progress.finish();
        },
        loadmerchants(searchQuery) {
            this.getResults(searchQuery);
        },
        searchMerchants: debounce(function () {
            this.merchants = [];
            this.lastLoadedOffset = 0;
            this.loadmerchants(this.searchQuery);
        }, 500),
        viewMerchant(merchant) {
            this.selectedMerchant = merchant;
            this.selectedMerchant.merchant_since = moment(merchant.created_at).format('MMMM Do YYYY');
            this.selectedMerchant.uninstall_date = moment(merchant.updated_at).format('MMMM Do YYYY');
            this.viewMode = true;
            $('#merchantModal').modal('show');
        },
        handleBoarding(merchant, actionType) {
            const isForce = actionType === 'force';
            const titleText = isForce ? 'This will force the merchant to go through the boarding process again.' : 'This will bypass the boarding process for this merchant.';
            const confirmButtonText = isForce ? 'Yes, force boarding' : 'Yes, bypass boarding';
            const successText = isForce ? 'Merchant has been forced to go through the boarding process again.' : 'Merchant has been bypassed.';
            const step = isForce ? 1 : 0;

            Swal.fire({
                title: 'Are you sure?',
                text: titleText,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: confirmButtonText,
                cancelButtonText: 'Cancel'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$Progress.start();
                    axios.put("api/boarding", {
                        merchant_id: merchant.merchant_id,
                        industry_type_id: merchant.industry_type_id ? merchant.industry_type_id : 0,
                        price_increase: '4.00',
                        discount_rate: merchant.cash_discount_rate,
                        processing_rate: merchant.cc_processing_fee,
                        step: step
                    }).then(({ data }) => {
                        this.$Progress.finish();
                        Swal.fire({
                            title: 'Success!',
                            text: successText,
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        }).then((result) => {
                            this.merchants = [];
                            this.lastLoadedOffset = 0;
                            this.loadmerchants(this.searchQuery);
                        });
                    }).catch(error => {
                        this.$Progress.fail();
                        Swal.fire({
                            title: 'Error!',
                            text: error.response.data.message,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        });
                    });
                }
            });
        },
        getStatus(merchant) {
            if (merchant.is_active == 1 && merchant.boarding_step != 0) {
                return 'Boarding';
            } else if (merchant.is_active == 1 && merchant.invalid_uninstall == 0) {
                return 'Active';
            } else if (merchant.invalid_uninstall != 0) {
                return 'Invalid Uninstall';
            } else {
                return 'Inactive';
            }
        },
        addMerchant() {
            const appId = this.cloverAppId;
            const redirectUri = encodeURIComponent(this.appUrl + '/clover/oauth_callback');
            const fullUrl = `${this.cloverOAuthUrl}/authorize?client_id=${appId}&response_type=code&redirect_uri=${redirectUri}`;

            window.location.href = fullUrl;
        },
        impersonateMerchant(merchantId) {
            this.$Progress.start();
            axios
                .post('api/clover-merchants/impersonate/' + merchantId)
                .then(({ data }) => {
                    this.$Progress.finish();
                    window.location.href = '/dashboard';
                })
                .catch(error => {
                    this.$Progress.fail();
                    Swal.fire({
                        title: 'Error!',
                        text: error.response.data.message,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                });
        },
        reactivateMerchant(merchantId) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Reactivating this account can take a moment.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, reactivate account',
                cancelButtonText: 'Cancel'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.reactivatingMerchant = true;
                    axios
                        .post('api/merchant/reactivate', {
                            merchant_id: merchantId
                        })
                        .then(({ data }) => {
                            this.reactivatingMerchant = false;
                            Swal.fire({
                                title: 'Success!',
                                text: 'Merchant account has been reactivated.',
                                icon: 'success',
                                confirmButtonText: 'Ok'
                            }).then((result) => {
                                this.merchants = [];
                                this.lastLoadedOffset = 0;
                                this.loadmerchants(this.searchQuery);
                            });
                        })
                        .catch(error => {
                            this.reactivatingMerchant = false;
                            if (error.response.data.message == 401) {
                                Swal.fire({
                                    title: 'Error!',
                                    text: 'Merchant account not linked! Please have merchant open the app to refresh token.',
                                    icon: 'error',
                                    confirmButtonText: 'Ok'
                                });
                            } else {
                                Swal.fire({
                                    title: 'Error!',
                                    text: error.response.data.message,
                                    icon: 'error',
                                    confirmButtonText: 'Ok'
                                });
                            }
                            console.log(error.response);
                            this.merchants = [];
                            this.lastLoadedOffset = 0;
                            this.loadmerchants(this.searchQuery);
                        });

                }
            });
        }
    }
}
</script>