<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12">

                    <div class="" v-if="$gate.hasPermission('users_view')">
                        <div class="card-header">
                            <h3 class="card-title">User List</h3>

                            <div class="card-tools card-tools-container">
                                <input type="text" name="search" class="form-control" placeholder="Search"
                                    v-model="searchQuery" @input="searchUsers" />
                                <div class="card-tools-buttons">
                                    <button v-if="$gate.hasPermission('users_add')" type="button"
                                        class="btn btn-sm btn-primary" @click="newModal">
                                        <i class="fa fa-plus-square"></i>
                                        Add New
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Role</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Azure AD Link</th>
                                        <th>Created</th>
                                        <th v-if="$gate.hasPermission('users_edit') || $gate.hasPermission('users_delete')">
                                            Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="user in users" :key="user.id">

                                        <td data-label="ID">{{ user.id }}</td>
                                        <td data-label="Role" class="text-capitalize">{{ roleNameMap[user.type] }}</td>
                                        <td data-label="Name" class="text-capitalize">{{ user.name }}</td>
                                        <td data-label="Email">{{ user.email }}</td>
                                        <td data-label="Azure AD Link?" :inner-html.prop="user.is_azure_ad | yesno">
                                        </td>
                                        <td data-label="Created">{{ user.created_at }}</td>

                                        <td data-label="Action">

                                            <a v-if="$gate.hasPermission('users_edit') && (user.type != 1 || $gate.isAdmin())"
                                                href="#" @click="editModal(user)">
                                                <i class="fa fa-edit blue"></i>
                                            </a>
                                            <a v-if="$gate.hasPermission('users_delete') && (user.type != 1 || $gate.isAdmin())"
                                                href="#" @click="deleteUser(user.id)">
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <div style="margin: 10px;" v-if="loading">Loading...</div>
                                    <div style="margin: 10px;" v-if="!loading && users.length === 0">No users found.</div>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>


            <div v-if="!$gate.hasPermission('users_view')">
                <not-found></not-found>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Create New User</h5>
                            <h5 class="modal-title" v-show="editmode">Update User's Info</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <!-- <form @submit.prevent="createUser"> -->

                        <form @submit.prevent="editmode ? updateUser() : createUser()">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input v-model="form.name" type="text" name="name" class="form-control"
                                        :class="{ 'is-invalid': form.errors.has('name') }">
                                    <has-error :form="form" field="name"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>Email</label>
                                    <input v-model="form.email" type="text" name="email" class="form-control"
                                        :class="{ 'is-invalid': form.errors.has('email') }"
                                        :disabled="form.is_azure_ad == 1 && !$gate.hasPermission('users_edit_azure_status')">
                                    <has-error :form="form" field="email"></has-error>
                                </div>
                                <div class="form-group"
                                    v-if="form.role_type != 'merchant' && ($gate.hasPermission('users_edit_azure_status') || !editmode)">
                                    <label for="is_azure_ad" class="control-label">
                                        Force Azure AD Login
                                        <span v-tooltip:right="'Require user to login using their Microsoft Account.'">
                                            <i class="far fa-question-circle"></i>
                                        </span>
                                    </label>
                                    <div class="col-sm-12">
                                        <div class="col-sm-12">
                                            <!-- <input type="checkbox"> -->
                                            <label class="switch" style="margin: 0px 0px 0px -30px;">
                                                <input type="checkbox" id="is_azure_ad" v-model="form.is_azure_ad"
                                                    v-bind:true-value="1" v-bind:false-value="0" data-toggle="toggle"
                                                    data-on="Yes" data-off="No" data-onstyle="success"
                                                    data-offstyle="danger" data-width="100">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" v-if="!form.is_azure_ad">
                                    <label>Password</label>
                                    <input v-model="form.password" type="password" name="password" class="form-control"
                                        :class="{ 'is-invalid': form.errors.has('password') }" autocomplete="false"
                                        @focus="showPolicy = true" @blur="showPolicy = false">
                                    <has-error :form="form" field="password"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>User Role</label>
                                    <select name="type" v-model="form.type" id="type" class="form-control"
                                        :class="{ 'is-invalid': form.errors.has('type') }">
                                        <option value="">Select User Role</option>
                                        <option v-for="role in roles" :key="role.id" :value="role.id"
                                            :disabled="!$gate.isAdmin() && role.id === 1">{{ role.name }}</option>
                                    </select>

                                    <has-error :form="form" field="type"></has-error>
                                </div>

                                <div id="password-policy" class="password-policy" v-show="showPolicy"
                                    style="box-shadow: none; margin-left: -10px; background-color: transparent;">
                                    <p>Password must meet the following requirements:</p>
                                    <ul>
                                        <li :class="{ 'valid': isMinCharsValid, 'invalid': !isMinCharsValid }">
                                            Minimum 8
                                            characters</li>
                                        <li :class="{ 'valid': isMaxCharsValid, 'invalid': !isMaxCharsValid }">
                                            Maximum
                                            64 characters</li>
                                        <li :class="{ 'valid': isUppercaseValid, 'invalid': !isUppercaseValid }">
                                            At
                                            least 1 uppercase letter</li>
                                        <li :class="{ 'valid': isTwoNumbersValid, 'invalid': !isTwoNumbersValid }">
                                            At
                                            least 2 consecutive numbers</li>
                                        <li :class="{ 'valid': isTwoSpecialValid, 'invalid': !isTwoSpecialValid }">
                                            At
                                            least 2 special characters</li>
                                        <li :class="{ 'valid': isNoSequentialValid, 'invalid': !isNoSequentialValid }">
                                            No sequential numbers (e.g. 123, 321)</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button v-show="editmode" type="submit" class="btn btn-primary">Update</button>
                                <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}
export default {
    data() {
        return {
            editmode: false,
            users: [],
            roles: [],
            form: new Form({
                id: '',
                type: '',
                role_type: '',
                name: '',
                email: '',
                password: '',
                merchant_id: '',
                email_verified_at: '',
                is_azure_ad: 0,
            }),
            loading: false,
            lastLoadedOffset: 0,
            searchQuery: "",
            page: 1,
            showPolicy: false,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    created() {
        this.$Progress.start();
        this.loadRoles();
        this.loadUsers();
        this.$Progress.finish();
    },
    watch: {
        'form.type'(newVal) {
            if (newVal) {
                const selectedRole = this.roles.find(role => role.id === newVal);
                if (selectedRole) {
                    this.form.role_type = selectedRole.type;
                }
            } else {
                this.form.role_type = null;
            }
        }
    },
    computed: {
        roleNameMap() {
            return this.roles.reduce((map, role) => {
                map[role.id] = role.name;
                return map;
            }, {});
        },
        isMinCharsValid() {
            return this.form.password && this.form.password.length >= 8;
        },
        isMaxCharsValid() {
            return this.form.password && this.form.password.length <= 64;
        },
        isUppercaseValid() {
            return /[A-Z]/.test(this.form.password);
        },
        isTwoNumbersValid() {
            return this.form.password && (this.form.password.match(/\d/g) || []).length >= 2;
        },

        isTwoSpecialValid() {
            return this.form.password && (this.form.password.match(/[@$!%*#?&]/g) || []).length >= 2;
        },

        isNoSequentialValid() {
            return !(/(012|123|234|345|456|567|678|789|987|876|765|654|543|432|321|210)/.test(this.form.password));
        },
        isPasswordValid() {
            return this.isMinCharsValid && this.isMaxCharsValid && this.isUppercaseValid && this.isTwoNumbersValid && this.isTwoSpecialValid && this.isNoSequentialValid;
        },
    },
    methods: {
        handleScroll() {
            const scrollY = window.scrollY + 1;
            const visibleHeight = window.innerHeight;
            const pageHeight = document.documentElement.scrollHeight;
            const offset = this.users.length;

            if (scrollY + visibleHeight >= pageHeight && !this.loading && offset !== this.lastLoadedOffset) {
                this.lastLoadedOffset = offset;
                this.getResults(this.searchQuery);
                console.log("Loading more users...");
            }
        },
        getResults(searchQuery) {

            this.$Progress.start();
            this.loading = true;
            if (this.$gate.hasPermission('users_view')) {
                axios
                    .get('api/users', {
                        params: {
                            offset: this.users.length,
                            search: searchQuery,
                        }
                    })
                    .then(({ data }) => {
                        this.users = this.users.concat(data.data);
                        this.loading = false;
                    });
            }
            this.$Progress.finish();
        },
        loadUsers(searchQuery) {
            // this.loading = true;
            this.getResults(searchQuery);
        },
        searchUsers: debounce(function () {
            this.users = [];
            this.lastLoadedOffset = 0;
            this.loadUsers(this.searchQuery);
        }, 500),
        async loadRoles() {
            if (this.$gate.hasPermission('users_view')) {
                let offset = 0;
                let allRoles = [];

                try {
                    while (true) {
                        const response = await axios.get("api/roles", {
                            params: {
                                offset: offset,
                            },
                        });

                        if (response.data.data.length === 0) {
                            break;
                        }

                        allRoles = allRoles.concat(response.data.data);
                        offset += response.data.data.length;
                    }
                } catch (error) {
                    console.error("Error loading roles:", error);
                }

                // Filter out the role with id 3 and 1 if the user is not an admin
                if (!this.$gate.isAdmin()) {
                    allRoles = allRoles.filter(role => role.id !== 2);
                    allRoles = allRoles.filter(role => role.id !== 1);
                }

                this.roles = allRoles;
            }
        },
        updateUser() {
            this.$Progress.start();
            if (this.$gate.hasPermission('users_edit')) {
                if (this.form.password && !this.isPasswordValid) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Password does not meet the requirements!'
                    });
                    this.$Progress.fail();
                    return;
                }
                if (!this.form.password) {
                    delete this.form.password;
                }
                if (this.form.type == 1 || this.form.type == 2 && !this.$gate.isAdmin()) {

                }
                this.form.put('api/users/' + this.form.id)
                    .then((response) => {
                        // success
                        $('#addNew').modal('hide');
                        Toast.fire({
                            icon: 'success',
                            title: response.data.message
                        });
                        this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');
                        this.users = [];
                        this.lastLoadedOffset = 0;
                        this.loadUsers();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                    });
            }
            this.$Progress.finish();
        },
        editModal(user) {
            this.editmode = true;
            this.form.reset();
            $('#addNew').modal('show');
            user.role_type = this.roles[user.type - 1].type;
            this.form.fill(user);
        },
        newModal() {
            this.editmode = false;
            this.form.reset();
            $('#addNew').modal('show');
        },
        deleteUser(id) {
            this.$Progress.start();

            if (this.$gate.hasPermission('users_delete')) {
                Swal.fire({
                    title: 'Are you sure you want to delete this user?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete user!',
                    cancelButtonText: 'No, cancel',
                }).then((result) => {
                    // Send request to the server
                    if (result.value) {
                        this.form.delete('api/users/' + id).then(() => {
                            Swal.fire(
                                'Deleted!',
                                'User has been deleted.',
                                'success'
                            );
                            // Fire.$emit('AfterCreate');
                            this.users = [];
                            this.lastLoadedOffset = 0;
                            this.loadUsers();
                        }).catch((data) => {
                            Swal.fire("Failed!", data.message, "warning");
                        });
                    }
                });
            }
            this.$Progress.finish();
        },
        createUser() {
            this.$Progress.start();
            if (this.$gate.hasPermission('users_add')) {
                this.form.password = this.form.is_azure_ad ? '' : this.form.password;
                if (this.form.password && !this.isPasswordValid) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Password does not meet the requirements!'
                    });
                    this.$Progress.fail();
                    return;
                }
                this.form.post('api/users')
                    .then((response) => {
                        $('#addNew').modal('hide');

                        Toast.fire({
                            icon: 'success',
                            title: response.data.message
                        });

                        this.users = [];
                        this.lastLoadedOffset = 0;
                        this.loadUsers();

                    })
                    .catch(() => {

                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                    });
                this.$Progress.finish();
            }
        }
    },
}
</script>
