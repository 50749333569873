export default [
    {
        path: '/dashboard',
        component: require('./components/Dashboard.vue').default,
        meta: { title: 'Dashboard' },
    },
    {
        path: '/boarding',
        component: require('./components/boarding/Boarding.vue').default,
        meta: { title: 'Boarding' },
    },
    {    
        path: '/account-settings',
        component: require('./components/user-management/AccountSettings.vue').default,
        meta: { title: 'Account Settings' },
    },
    {
        path: '/uninstall',
        component: require('./components/user-management/Uninstall.vue').default,
        meta: { title: 'Uninstall' },
    },
    { 
        path: '/products', 
        component: require('./components/products/Products.vue').default,
        meta: { title: 'Products' },
    },
    { 
        path: '/modifier-groups', 
        component: require('./components/modifier-groups/ModifierGroups.vue').default,
        meta: { title: 'Modifier Groups' },
    },
    { 
        path: '/support', 
        component: require('./components/support/Support.vue').default,
        meta: { title: 'Support' },
    },
    {
        path: '/merchants',
        component: require('./components/merchants/Merchants.vue').default,
        meta: { title: 'Merchants' },
    },
    { 
        path: '/users', 
        component: require('./components/user-management/Users.vue').default,
        meta: { title: 'Users' },
    },
    { 
        path: '/roles', 
        component: require('./components/user-management/Roles.vue').default,
        meta: { title: 'Roles' },
    },
    {
        path: '/permissions',
        component: require('./components/user-management/Permissions.vue').default,
        meta: { title: 'Permissions' },
    },
    // Delete below after migration
    {
        path: '/migrate',
        component: require('./components/migration/Migration-Tool.vue').default,
        meta: { title: 'TCD Migration Tool' },
    },
    // Delete above after migration   
    { 
        path: '*', 
        component: require('./components/NotFound.vue').default,
    },
    {
        path: '/login',
        meta: { title: 'Login' },
    },
    {
        path: '/register',
        meta: { title: 'Register' },
    },
];
