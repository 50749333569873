export default class Gate {
  constructor(user) {
    this.user = user;
  }

  hasRole(roleType) {
    return this.user.roles.some((userRole) => userRole.type === roleType);
  }

  hasProgram(programType) {
    return this.user.roles.some((userRole) => userRole.program_type === programType) || (this.isImpersonating() && this.impersonationDetails().program_type === programType);
  }

  hasPermission(permission) {
    return this.user.roles.some((role) =>
      role.permissions.some((perm) => perm.title === permission)
    );
  }

  isAdmin() {
    return this.hasRole('admin');
  }

  isSupport() {
    return this.hasRole('support');
  }

  isMerchant() {
    return this.hasRole('merchant');
  }

  isCDP() {
    return this.hasProgram('cdp');
  }

  isDP() {
    return this.hasProgram('dp');
  }

  isImpersonating() {
    return window.isImpersonating;
  }

  impersonationDetails() {
    return window.impersonationDetails;
  }

  isAzureAD() {
    return this.user.isAzureAD;
  }

}
