/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');
import moment from 'moment';

import { Form, HasError, AlertError } from 'vform';
window.Form = Form;

import Gate from "./Gate";
Vue.prototype.$gate = new Gate(window.user);

import Swal from 'sweetalert2';

/**
 * Tooltip Directive
 * 
 * Provides a custom tooltip functionality for Vue components. This directive
 * automatically adjusts the tooltip trigger based on the device type (touch or non-touch).
 * On non-touch devices, tooltips are shown on hover, while on touch devices, they are
 * shown on tap. Additionally, on touch devices, tapping outside the tooltip will hide it.
 *
 * Usage:
 * <element v-tooltip="{ title: 'Tooltip text', placement: 'top' }"></element>
 */
Vue.directive('tooltip', {
  bind(el, binding, vnode) {
    // Determine if the device supports touch events
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    // Set trigger based on the device type
    const trigger = isTouchDevice ? 'click' : 'hover';

    // Initialize the tooltip with jQuery
    $(el).tooltip({
      title: binding.value,
      placement: binding.arg || 'top',
      trigger: trigger,
    });

    if (isTouchDevice) {
      // Define a function to hide the tooltip when tapping outside of it
      const hideTooltip = (event) => {
        if (!el.contains(event.target)) {
          $(el).tooltip('hide');
        }
      };

      // Add a touchstart event listener to hide the tooltip on outside tap
      document.addEventListener('touchstart', hideTooltip);

      // Store the event listener function for later removal
      el.dataset.hideTooltip = hideTooltip;
    }
  },
  unbind(el) {
    // Dispose of the tooltip when the directive is unbound
    $(el).tooltip('dispose');

    // Remove the touchstart event listener if it was added
    if (el.dataset.hideTooltip) {
      document.removeEventListener('touchstart', el.dataset.hideTooltip);
      delete el.dataset.hideTooltip;
    }
  },
});

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
window.Swal = Swal;
window.Toast = Toast;

import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
    color: 'rgb(143, 255, 199)',
    failedColor: 'red',
    height: '3px'
  });

Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)


/**
 * Routes imports and assigning
 */
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import routes from './routes';

const router = new VueRouter({
    mode: 'history',
    routes
});


// if document.isBoarding is true, then redirect to boarding page
if (window.isBoarding) {
    router.beforeEach((to, from, next) => {
        if (to.path !== '/boarding') {
            next('/boarding');
        } else {
            next();
        }
    });
} else {
    router.beforeEach((to, from, next) => {
        if (to.path === '/boarding') {
            next('/');
        } else {
            next();
        }
    });
}

window.pageTitle = document.title;

window.updatePageTitle = function(title) {
    window.pageTitle = title;
    document.title = title;
    const pageTitleElement = document.getElementById('page-title');
    if (pageTitleElement) {
        pageTitleElement.textContent = title;
    }
}

router.beforeEach((to, from, next) => {
    const pageTitle = to.meta.title || 'True Cash Discount';
    window.updatePageTitle(pageTitle);
    next();
});

  
// Routes End


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

 
// Components
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('dashboard', require('./components/Dashboard.vue'));

Vue.component(
    'not-found',
    require('./components/NotFound.vue').default
);

// Filter Section

Vue.filter('myDate',function(created){
    return moment(created).format('MMMM Do YYYY');
});

Vue.filter('yesno', value => (value ? '<i class="fas fa-check green"></i>' : '<i class="fas fa-times red"></i>'));

// end Filter

const app = new Vue({
    el: '#app',
    router
});

import axios from 'axios';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // Show the popup when the user is not authenticated
      window.showSessionTimeoutPopup();
    }
    return Promise.reject(error);
  }
);