<template>
<div></div>
</template>

<script>
    export default {
        mounted() {
            window.location = "/home";  
        }
    }
</script>
