<style lang="scss">
@import '../../../../public/css/custom.css';
</style>
<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12">
                    <div
                        v-if="!$gate.hasPermission('modifier_groups_view') || (($gate.isAdmin() || $gate.isSupport()) && !$gate.isImpersonating())">
                        <not-found></not-found>
                    </div>
                    <div class="" v-if="$gate.hasPermission('modifier_groups_view')">
                        <div class="card-header">
                            <h3 class="card-title">Group List<span class="count-text"> &colon; Groups: {{ groupCount }}
                                    &colon; Adjusted Items: {{ adjustedCount }}/{{ totalCount }}</span></h3>

                            <div v-if="!syncing && !adjustingPrice" class="card-tools card-tools-container">
                                <input type="text" name="search" class="form-control" placeholder="Search"
                                    v-model="searchQuery" @input="searchModifierGroups" />
                                <div class="card-tools-buttons">

                                    <button type="button" v-if="$gate.hasPermission('modifier_groups_price_adjust_all')"
                                        class="btn btn-sm btn-primary" @click="adjustPrice(0, 0)">
                                        <i class="fas fa-money-check-alt"></i>
                                        Adjust All Prices
                                    </button>
                                    <button type="button" v-if="$gate.hasPermission('modifier_groups_price_revert_all')"
                                        class="btn btn-sm btn-primary" @click="revertPrice(0, 0)">
                                        <i class="fas fa-undo-alt"></i>
                                        Revert All Prices
                                    </button>
                                    <button v-if="$gate.hasPermission('modifier_groups_force_sync')" type="button"
                                        class="btn btn-sm btn-primary" @click="syncItems">
                                        <i class="fa fa-sync"></i>
                                        Sync Items
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Adjustment</th>
                                        <th
                                            v-if="$gate.hasPermission('modifier_groups_price_revert_group') || $gate.hasPermission('modifier_groups_price_adjust_group')">
                                            Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="group in groups">
                                        <tr :key="group.id" v-if="!syncing && !adjustingPrice">
                                            <td data-label="Name" @click="fetchItems(group.group_id, group)"
                                                style="cursor: pointer;">
                                                <i
                                                    :class="['fas', selectedGroup && selectedGroup.group_id === group.group_id ? 'fa-chevron-down' : 'fa-chevron-right']"></i>
                                                {{ group.name }}
                                            </td>
                                            <td data-label="Increased Range">{{ group.increased_range }}</td>
                                            <td data-label="Action"
                                                v-if="$gate.hasPermission('modifier_groups_price_revert_single') || $gate.hasPermission('modifier_groups_price_adjust_single')">
                                                <a v-if="group.is_increased && $gate.hasPermission('modifier_groups_price_revert_group')"
                                                    href="#" @click="revertPrice(group.group_id, 2)">
                                                    <i class="fas fa-undo-alt"></i>
                                                    Revert Price
                                                </a>
                                                <a v-if="!group.is_increased && $gate.hasPermission('modifier_groups_price_adjust_group')"
                                                    href="#" @click="adjustPrice(group.group_id, 2)">
                                                    <i class="fas fa-edit"></i>
                                                    Adjust Price
                                                </a>
                                            </td>
                                        </tr>
                                        <tr v-if="selectedGroup && selectedGroup.group_id === group.group_id"
                                            :key="group.id + '-selected'" class="no-hover">
                                            <td colspan="3" class="table-inner-td">
                                                <div class="table-inner-body animate-table">
                                                    <table class="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Original Price</th>
                                                                <th>Current Price</th>
                                                                <th>Adjustment</th>
                                                                <th
                                                                    v-if="$gate.hasPermission('modifier_groups_price_revert_single') || $gate.hasPermission('modifier_groups_price_adjust_single')">
                                                                    Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in items" :key="item.id">
                                                                <td data-label="Name">{{ item.name }}</td>
                                                                <td data-label="Original Price">${{ item.initialPrice }}
                                                                </td>
                                                                <td data-label="Current Price">${{ item.currentPrice }}</td>
                                                                <td data-label="Adjustment">{{ item.increased_percent }}%
                                                                </td>
                                                                <td data-label="Action"
                                                                    v-if="$gate.hasPermission('modifier_groups_price_revert_single') || $gate.hasPermission('modifier_groups_price_adjust_single')">
                                                                    <a v-if="item.is_increased && $gate.hasPermission('modifier_groups_price_revert_single')"
                                                                        href="#" @click="revertPrice(item.item_id, 1)">
                                                                        <i class="fas fa-undo-alt"></i>
                                                                        Revert Price
                                                                    </a>
                                                                    <a v-if="!item.is_increased && $gate.hasPermission('modifier_groups_price_adjust_single')"
                                                                        href="#" @click="adjustPrice(item.item_id, 1)">
                                                                        <i class="fas fa-edit"></i>
                                                                        Adjust Price
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    <div style="margin: 10px;" v-if="loading">Loading...</div>
                                    <div class="syncing-screen" v-if="syncing && $gate.isImpersonating()">
                                        <div class="loading-spinner"></div>
                                        <div class="loading-text">Syncing items...<br>{{ syncSubText }}</div>
                                    </div>
                                    <div class="syncing-screen" v-if="adjustingPrice && $gate.isImpersonating()">
                                        <div class="loading-spinner"></div>
                                        <div class="loading-text">{{ priceAdjustmentText }}<br>{{ priceAdjustmentSubText }}
                                        </div>
                                    </div>
                                    <div style="margin: 10px;"
                                        v-if="!loading && groups.length === 0 && !syncing && !adjustingPrice">
                                        No groups found.
                                        <span v-tooltip:right="'Please verify that items are synced with Clover.'">
                                            <i class="far fa-question-circle"></i>
                                        </span>
                                    </div>
                                </tbody>
                            </table>

                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                        </div>
                        <div v-if="syncing && !$gate.isImpersonating()" class="loading-screen">
                            <div class="loading-spinner"></div>
                            <div class="loading-text">Syncing items...<br>{{ syncSubText }}</div>
                        </div>
                        <div v-if="adjustingPrice && !$gate.isImpersonating()" class="loading-screen">
                            <div class="loading-spinner"></div>
                            <div class="loading-text">{{ priceAdjustmentText }}<br>{{ priceAdjustmentSubText }}</div>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="priceAdjust" tabindex="-1" role="dialog" aria-labelledby="priceAdjust"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="modal-title">
                                <h4 v-if="adjustSingle">Increase Price of Item</h4>
                                <h4 v-if="adjustGroup">Increase Price of All Items in Group</h4>
                                <h4 v-if="adjustAll">Increase Price of All Groups</h4>
                            </div>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <label for="percentage" class="col-md-6 col-form-label">Increase Percent:</label>
                            <div class="col-md-12">
                                <input v-model="percentage" ref="percentage" id="percentage" type="number" pattern="\d*"
                                    min="0" max="100" class="form-control" @input="validateInput" @keypress="checkKeyPress">
                                <div v-if="showError" style="color: red">
                                    Value must be between 0.01 and 100.00.
                                </div>
                            </div>
                            <br>
                            <div class="col-md-12">
                                <button v-if="adjustSingle"
                                    @click="setPriceAdjustment('single', adjustItemId); resetPercentage()"
                                    class="btn btn-md btn-primary" :disabled="showError">Adjust Price</button>
                                <button v-if="adjustGroup"
                                    @click="setPriceAdjustment('group', adjustGroupId); resetPercentage()"
                                    class="btn btn-md btn-primary" :disabled="showError">Adjust Group Prices</button>
                                <button v-if="adjustAll" @click="setPriceAdjustment('all', 0); resetPercentage()"
                                    class="btn btn-md btn-primary" :disabled="showError">Adjust All Group Prices</button>
                                <button class="btn btn-md btn-secondary" data-dismiss="modal" @click="resetPercentage"
                                    aria-label="Close">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import Swal from 'sweetalert2';
function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}
export default {
    data() {
        return {
            editmode: false,
            groups: [],
            items: [],
            page: 1,
            lastLoadedOffset: 0,
            loading: false,
            syncing: false,
            syncSubText: "This may take a moment.",
            percentage: '4.00',
            showError: false,
            adjustSingle: false,
            adjustGroup: false,
            adjustAll: false,
            adjustItemId: 0,
            adjustGroupId: 0,
            adjustingPrice: false,
            selectedGroup: null,
            previousSelectedGroup: null,
            searchQuery: "",
            adjustedCount: 0,
            totalCount: 0,
            groupCount: 0,
            priceAdjustmentText: "Undergoing Price Adjustment...",
            priceAdjustmentSubText: "This may take a few minutes.",
            checkingJobStatus: false,
        };
    },
    created() {
        this.checkJobStatusUntilComplete();
        this.loadgroups();
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.checkingJobStatus = false;
    },
    beforeDestroy() {
        this.checkingJobStatus = false;
    },
    methods: {
        handleScroll() {
            const scrollY = window.scrollY + 1;
            const visibleHeight = window.innerHeight;
            const pageHeight = document.documentElement.scrollHeight;
            const offset = this.groups.length;

            if (scrollY + visibleHeight >= pageHeight && !this.loading && offset !== this.lastLoadedOffset) {
                this.lastLoadedOffset = offset;
                this.getResults(this.searchQuery);
                console.log("Loading more groups...");
            }
        },
        getResults(searchQuery) {
            this.$Progress.start();
            this.loading = true;
            if (this.$gate.hasPermission('modifier_groups_view') && !this.syncing && !this.adjustingPrice) {
                axios
                    .get('api/modifier-groups', {
                        params: {
                            offset: this.groups.length,
                            search: searchQuery,
                        }
                    })
                    .then(({ data }) => {
                        const updatedgroups = data.data.groups.map(group => {
                            return {
                                ...group,
                                initialPrice: (group.initial_price / 100).toFixed(2),
                                currentPrice: (group.current_price / 100).toFixed(2)
                            };
                        });
                        if (this.groups.length === 0) {
                            this.adjustedCount = data.data.adjusted_count;
                            this.totalCount = data.data.total_count;
                            this.groupCount = data.data.group_count;
                        }
                        this.groups = [...this.groups, ...updatedgroups];
                        this.loading = false;
                    });
            }
            this.$Progress.finish();
        },
        fetchItems(groupId, group) {
            if (this.selectedGroup && this.selectedGroup.group_id === groupId) {
                // If the selected group is already open, close it
                this.selectedGroup = null;
                this.items = [];
            } else {
                this.$Progress.start();
                this.loading = true;
                axios
                    .get('api/modifier-groups/' + groupId + '/items')
                    .then(({ data }) => {
                        const updatedgroups = data.data.map(group => {
                            return {
                                ...group,
                                initialPrice: (group.initial_price / 100).toFixed(2),
                                currentPrice: (group.current_price / 100).toFixed(2)
                            };
                        });
                        this.selectedGroup = group;
                        this.items = []; // Clear items array before updating it
                        this.items = updatedgroups;
                    })
                    .finally(() => {
                        this.$Progress.finish();
                        this.loading = false;
                    });
            }
        },
        loadgroups(searchQuery) {
            this.getResults(searchQuery);
        },
        searchModifierGroups: debounce(function () {
            this.groups = [];
            this.lastLoadedOffset = 0;
            this.loadgroups(this.searchQuery);
        }, 300),
        syncItems() {
            Swal.fire({
                title: 'Sync Items',
                text: 'Are you sure you want to sync modifier groups? This may a few minutes.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, sync groups',
                cancelButtonText: 'Cancel'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.groups = [];
                    this.lastLoadedOffset = 0;
                    this.selectedGroup = null;
                    this.syncing = true;
                    // If user confirmed, make the API request
                    axios.get('api/modifier-groups/sync-all-clover-modifier-groups-to-db')
                        .then((response) => {
                            if (response.data.status === 1) {
                                this.showSyncSuccess();
                            } else {
                                this.showSyncError();
                            }
                            this.loadgroups();
                            if (this.previousSelectedGroup) {
                                this.fetchItems(this.previousSelectedGroup.group_id, this.previousSelectedGroup);
                            }
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(error);
                            this.showSyncError();
                            this.loading = false;
                        });
                }
            });
        },
        showSyncSuccess() {
            Swal.fire({
                title: 'Sync Successful',
                text: 'All items have been synced',
                icon: 'success'
            });
            this.syncing = false;
        },
        showSyncError() {
            Swal.fire({
                title: 'Sync Failed',
                text: 'There was an error syncing items',
                icon: 'error'
            });
            this.syncing = false;
        },
        adjustPrice(id, type) {
            if (type === 1) {
                this.adjustGroup = false;
                this.adjustSingle = true;
                this.adjustAll = false;
                this.adjustItemId = id;
            } else if (type === 2) {
                this.adjustGroup = true;
                this.adjustSingle = false;
                this.adjustAll = false;
                this.adjustGroupId = id;
            } else {
                this.adjustSingle = false;
                this.adjustGroup = false;
                this.adjustAll = true;
            }
            $('#priceAdjust').modal('show');
        },
        revertPrice(id, type) {
            if (type == 1) {
                // Swal confirm button
                Swal.fire({
                    title: 'Revert Item',
                    text: 'Are you sure you want to revert this item?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, revert item',
                    cancelButtonText: 'Cancel'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setPriceAdjustment('revert-single', id);
                    }
                });
            } else if (type == 2) {
                Swal.fire({
                    title: 'Revert Group',
                    text: 'Are you sure you want to revert this group?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, revert group',
                    cancelButtonText: 'Cancel'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setPriceAdjustment('revert-group', id);
                    }
                });
            } else {
                Swal.fire({
                    title: 'Revert Groups',
                    text: 'Are you sure you want to revert all groups? This may a few minutes.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, revert groups',
                    cancelButtonText: 'Cancel'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setPriceAdjustment('revert-all', 0);
                    }
                });
            }
        },
        checkJobStatus() {
            return new Promise((resolve, reject) => {
                axios.get('api/user/job-status')
                    .then((response) => {
                        if (response.data.type === 'sync') {
                            if (response.data.status === 0) {
                                this.showSyncError();
                                this.groups = [];
                                this.lastLoadedOffset = 0;
                                this.loadgroups();
                                this.loading = false;
                            } else if (response.data.status === 1) {
                                this.showSyncSuccess();
                                this.groups = [];
                                this.lastLoadedOffset = 0;
                                this.loadgroups();
                                this.loading = false;
                            } else if (response.data.status === 2) {
                                this.syncing = true;
                                this.groups = [];
                                this.lastLoadedOffset = 0;
                                this.syncSubText = response.data.data.progress;
                            }
                        } else if (response.data.type === 'merchant') {
                            if (response.data.status === 0) {
                                this.adjustingPrice = false;
                                reject(true);
                                this.showAdjustmentError();
                            } else if (response.data.status === 1) {
                                this.adjustingPrice = false;
                                resolve(true);
                            } else if (response.data.status === 2) {
                                this.adjustingPrice = true;
                                this.groups = [];
                                this.lastLoadedOffset = 0;
                                this.priceAdjustmentText = "Adjustment in progress...";
                                this.priceAdjustmentSubText = response.data.data.item_count + "/" + response.data.data.total_items + " items adjusted";
                                resolve(false);
                            } else if (response.data.status === 3) {
                                this.adjustingPrice = true;
                                this.groups = [];
                                this.lastLoadedOffset = 0;
                                this.retryFailedAdjustments(response.data.data.retryId);
                                resolve(false);
                            } else {
                                if (this.adjustingPrice == true) {
                                    this.adjustingPrice = false;
                                    resolve(true);
                                }

                                if (this.syncing == true) {
                                    this.showSyncSuccess();
                                    this.groups = [];
                                    this.lastLoadedOffset = 0;
                                    this.loadgroups();
                                    this.loading = false;
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        async checkJobStatusUntilComplete() {
            this.checkingJobStatus = true;
            let isJobComplete = false;
            while (!isJobComplete && this.checkingJobStatus) {
                if (this.checkingJobStatus == false) {
                    break;
                }
                try {
                    isJobComplete = await this.checkJobStatus();
                    if (!isJobComplete) {
                        await new Promise(resolve => setTimeout(resolve, 5000));
                    } else {
                        await this.showAdjustmentSuccess();
                    }
                } catch (error) {
                    console.error('Error checking job status:', error);
                }
            }
            this.checkingJobStatus = false;
        },
        async setPriceAdjustment(type, id) {
            $('#priceAdjust').modal('hide');
            this.adjustingPrice = true;
            this.groups = [];
            this.selectedGroup = null;
            this.lastLoadedOffset = 0;
            this.priceAdjustmentText = "Undergoing Price Adjustment...";
            this.priceAdjustmentSubText = "This may take a few minutes.";

            if (this.selectedGroup) {
                this.previousSelectedGroup = this.selectedGroup;
                this.fetchItems(this.selectedGroup.group_id, this.selectedGroup);
            }

            try {
                const payload = {
                    type: type,
                    percentage: this.percentage
                };

                if (type === 'single' || type === 'revert-single') {
                    payload.item_id = id;
                } else if (type === 'all' || type === 'revert-all' || type === 'group' || type === 'revert-group') {
                    payload.group_id = id;
                }

                const timeoutPromise = new Promise(resolve => setTimeout(resolve, 3000));

                const responsePromise = axios.post('api/modifier-groups/adjust-price', payload);

                if (type !== 'single' && type !== 'revert-single') {
                    await Promise.race([responsePromise, timeoutPromise]);
                } else {
                    await responsePromise;
                    this.showAdjustmentSuccess();
                }

                this.checkJobStatusUntilComplete();


            } catch (error) {
                console.error(error);
                this.showAdjustmentError();
            }
        },
        async retryFailedAdjustments(retryId) {
            this.adjustingPrice = true;
            this.priceAdjustmentText = "Wrapping up, just a few moments.";
            this.priceAdjutmentSubText = "Retrying Failed Price Adjustments...";

            try {
                await axios.post('api/modifier-groups/adjust-price', { retryId });
            } catch (error) {
                console.error(error);
                this.showAdjustmentError();
            }
        },
        async showAdjustmentSuccess() {
            this.adjustingPrice = false;

            if (this.previousSelectedGroup) {
                this.fetchItems(this.previousSelectedGroup.group_id, this.previousSelectedGroup);
            }

            return Swal.fire({
                title: 'Prices Adjusted',
                html: '<p class="adjustment-text">To see price changes on device, open the Clover Inventory App and refresh the inventory.</p>',
                icon: 'success'
            }).then(() => {
                this.adjustingPrice = false;
                this.groups = [];
                this.loadgroups();
                if (this.previousSelectedGroup) {
                    this.fetchItems(this.previousSelectedGroup.group_id, this.previousSelectedGroup);
                }
            });
        },
        showAdjustmentError() {
            this.adjustingPrice = false;
            Swal.fire({
                title: 'Price Adjustment Failed',
                text: 'There was an error adjusting the prices',
                icon: 'error'
            });
        },
        setCursorToEnd(el) {
            if (typeof el.selectionStart === "number") {
                el.selectionStart = el.selectionEnd = el.value.length;
            } else if (typeof el.createTextRange !== "undefined") {
                el.focus();
                var range = el.createTextRange();
                range.collapse(false);
                range.select();
            }
        },
        validateInput() {
            if (!window.isCloverDevice && !window.AndroidInterface) {
                this.formatNum();
            }
            let num = parseFloat(this.percentage);
            if (window.isCloverDevice || window.AndroidInterface) {
                let decimalPart = this.percentage.split(".")[1];
                if (decimalPart && decimalPart.length > 2) {
                    // Don't allow more than 2 decimal places
                    this.percentage = this.percentage.slice(0, this.percentage.length - 1);
                }
            }
            if (isNaN(num) || num < 0.01 || num > 100) {
                this.showError = true;
            } else {
                this.showError = false;
            }
            // Set the cursor position after validation
            this.$nextTick(() => {
                this.setCursorToEnd(this.$refs.percentage);
            });
        },
        formatNum() {
            var num = this.percentage.replace(/\D/g, '');

            // Ensure the number isn't too large 
            num = num.length > 5 ? num.slice(0, 5) : num;

            // Pad the number with leading zeros if it's too short 
            while (num.length < 3) {
                num = '0' + num;
            }

            // Insert the decimal point 
            num = num.slice(0, num.length - 2) + '.' + num.slice(num.length - 2);

            // Remove leading zeros (but ensure there's always one before the decimal point) 
            num = num.replace(/^0+([1-9])/, '$1').replace(/^0+\./, '0.');

            this.percentage = num;

            // Set the cursor position after formatting
            this.$nextTick(() => {
                this.setCursorToEnd(this.$refs.percentage);
            });
        },
        checkKeyPress(event) {
            const keyCode = event.keyCode ? event.keyCode : event.which;
            const keyValue = String.fromCharCode(keyCode);
            if (["e", "+", "-"].includes(keyValue.toLowerCase())) {
                event.preventDefault();
            }
        },
        resetPercentage() {
            this.percentage = '4.00';
            this.showError = false;
        },
    }
}
</script>