<style lang="scss">
    @import '../../../../public/css/custom.css';
</style>
<template>
    <section class="content">
      <div class="container-fluid">
          <div class="row">
  
            <div class="col-12">
          
            <div v-if="!$gate.hasPermission('permissions_view')">
                <not-found></not-found>
            </div>
              <div class="" v-if="$gate.hasPermission('permissions_view')">
                <div class="card-header">
                  <h3 class="card-title">Permission List</h3>
                    <div class="card-tools card-tools-container">
                        <input
                            type="text"
                            name="search"
                            class="form-control"
                            placeholder="Search"
                            v-model="searchQuery"
                            @input="searchPermissions"
                        />
                        <div class="card-tools-buttons">
                            <button type="button" v-if="$gate.hasPermission('permissions_add')" class="btn btn-sm btn-primary" @click="addPermissionModal()">
                                <i class="fa fa-plus-square"></i>
                                Add New
                            </button>
                        </div>
                    </div>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive p-0">
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="permission in permissions" :key="permission.id">
                            <td data-label="ID">{{permission.id}}</td>
                            <td data-label="Title">{{permission.title}}</td>
                            <td data-label="Action">
                                <a v-if="$gate.hasPermission('permissions_edit')" href="#" @click="editPermissionModal(permission)">
                                    <i class="fa fa-edit blue"></i>
                                </a>
                                <a href="#" @click="viewPermissionModal(permission)"><i class="fa fa-eye"></i> </a> 
                            </td>
                        </tr>
                        <div style="margin: 10px;" v-if="loading">Loading...</div>
                        <div style="margin: 10px;" v-if="!loading && permissions.length === 0">No permissions found.</div>
                        </tbody>
                    </table>
                    </div>
                    <!-- /.card-body -->
                    <div class="card-footer">
                    </div>
                </div>
              <!-- /.card -->
            </div>
          </div>
            <!-- Modal -->
            <div class="modal fade" id="permissionModal" tabindex="-1" role="dialog" aria-labelledby="permissionModal" aria-hidden="true">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                        <div class="modal-title">
                            <h4 v-if="addingPermission">Create New Permission</h4>
                            <h4 v-if="editingPermission">Edit Permission</h4>
                            <h4 v-if="viewingPermission">View Permission</h4>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                        <div class="modal-body">
                            <label v-if="viewingPermission" for="permission-id" class="col-md-4 col-form-label">Permission ID</label>
                            <div v-if="viewingPermission" class="col-md-12">
                                <input v-model="form.id" id="permission-id" type="text" class="form-control" disabled>
                            </div>
                            <label for="permission-title" class="col-md-4 col-form-label">Permission Title</label>
                            <div class="col-md-12">
                                <input v-model="form.title" id="permission-title" type="text" class="form-control">
                            </div>
                            <br>
                            <div class="col-md-12">
                                <button v-if="addingPermission" @click="createPermission(form.title)" class="btn btn-md btn-primary">Create</button>
                                <button v-if="editingPermission" @click="editPermission(form.id, form.title)" class="btn btn-md btn-primary">Edit</button>
                                <button v-if="!viewingPermission" class="btn btn-md btn-secondary" data-dismiss="modal" aria-label="Close">Cancel</button>
                                <button v-if="viewingPermission" class="btn btn-md btn-secondary" data-dismiss="modal" aria-label="Close">Close</button>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
  </template>
  
  <script>
    import Swal from 'sweetalert2';
    import $ from 'jquery';
    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }
    export default {
        data() {
            return {
                editmode: false,
                permissions: [],
                page: 1,
                lastLoadedOffset: 0,
                loading: false,
                form: new Form({
                    id : '',
                    title : '',
                }),
                addingPermission: false,
                editingPermission: false,
                viewingPermission: false,
                searchQuery: "",
            };
        },
        created() {
            this.loadPermissions();
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll);
        },
        beforeUnmount() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll() {
                const scrollY = window.scrollY + 1;
                const visibleHeight = window.innerHeight;
                const pageHeight = document.documentElement.scrollHeight;
                const offset = this.permissions.length;

                if (scrollY + visibleHeight >= pageHeight && !this.loading && offset !== this.lastLoadedOffset) {
                    this.lastLoadedOffset = offset;
                    this.getResults(this.searchQuery);
                    console.log("Loading more permissions...");
                }
            },
            getResults(searchQuery) {
                this.$Progress.start();
                this.loading = true;
                if (this.$gate.hasPermission("permissions_view")) {
                    axios
                    .get("api/permissions", {
                        params: {
                        offset: this.permissions.length,
                        search: searchQuery,
                        },
                    })
                    .then(({ data }) => {
                        this.permissions = this.permissions.concat(data.data);
                        this.loading = false;
                    });
                }
                this.$Progress.finish();
            },
            loadPermissions(searchQuery) {
                this.getResults(searchQuery);
            },
            searchPermissions: debounce(function () {
                this.permissions = [];
                this.lastLoadedOffset = 0;
                this.loadPermissions(this.searchQuery);
            }, 300),
            reloadPermissions() {
                this.permissions = [];
                this.loadPermissions();
            },
            addPermissionModal() {
                this.form.reset();
                this.addingPermission = true;
                this.editingPermission = false;
                this.viewingPermission = false;
                $('#permissionModal').modal('show');
            },
            editPermissionModal(permission) {
                this.editingPermission = true;
                this.addingPermission = false;
                this.viewingPermission = false;
                this.form.title = permission.title;
                this.form.id = permission.id;
                $('#permissionModal').modal('show');
            },
            viewPermissionModal(permission) {
                this.viewingPermission = true;
                this.addingPermission = false;
                this.editingPermission = false;
                this.form.title = permission.title;
                this.form.id = permission.id;
                $('#permission-title').prop('disabled', true);
                $('#permissionModal').modal('show');
            },
            createPermission(title) {
                this.$Progress.start();
                if(this.$gate.hasPermission('permissions_add')){
                    $('#permissionModal').modal('hide');
                    // Remove any special characters from the title
                    const cleanTitle = title.replace(/[^\w\s]/gi, '');

                    // Format the title as lowercase with underscores
                    const formattedTitle = cleanTitle.trim().replace(/\s+/g, '_').toLowerCase();

                    // Make the API request with the formatted title as the parameter
                    axios.post('api/permissions', {
                        permission_title: formattedTitle
                    })
                    .then(response => {
                        if(response.data.status === 1) {
                            Swal.fire({
                                title: 'Success!',
                                text: response.data.message,
                                icon: 'success'
                            });
                            this.loadPermissions();
                        } else {
                            Swal.fire({
                                title: 'Error!',
                                text: response.data.message,
                                icon: 'error'
                            });
                            this.loadPermissions();
                        }
                    })
                    .catch(error => {
                        Swal.fire({
                            title: 'Error!',
                            text: error.message,
                            icon: 'error'
                        });
                        this.loadPermissions();
                    });
                }
                this.$Progress.finish();
            },
            editPermission(permissionId, newTitle) {
                this.$Progress.start();
                if(this.$gate.hasPermission('permissions_edit')){
                    $('#permissionModal').modal('hide');
                    // Remove any special characters from the title
                    const cleanTitle = newTitle.replace(/[^\w\s]/gi, '');

                    // Format the title as lowercase with underscores
                    const formattedTitle = cleanTitle.trim().replace(/\s+/g, '_').toLowerCase();

                    // Make the API request with the formatted title as the parameter
                    axios.put('api/permissions/' + this.permissionId, {
                        permission_id: permissionId,
                        permission_new_title: formattedTitle
                    })
                    .then(response => {
                        if(response.data.status === 1) {
                            Swal.fire({
                                title: 'Success!',
                                text: response.data.message,
                                icon: 'success'
                            });
                            this.reloadPermissions();
                        } else {
                            Swal.fire({
                                title: 'Error!',
                                text: response.data.message,
                                icon: 'error'
                            });
                            this.reloadPermissions();
                        }
                    })
                    .catch(error => {
                        Swal.fire({
                            title: 'Error!',
                            text: error.message,
                            icon: 'error'
                        });
                        this.reloadPermissions();
                    });
                }
                this.$Progress.finish();
            },
        }
    }
</script>