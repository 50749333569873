<style lang="scss">
@import '../../../../public/css/custom.css';
</style>
<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12">
                    <div
                        v-if="!$gate.hasPermission('products_view') || (($gate.isAdmin() || $gate.isSupport()) && !$gate.isImpersonating())">
                        <not-found></not-found>
                    </div>
                    <div class="" v-if="$gate.hasPermission('products_view')">
                        <div class="card-header">
                            <h3 class="card-title">Product List<span class="count-text"> &colon; Adjusted Items: {{
                                adjustedCount }}/{{ totalCount }}</span></h3>
                            <div v-if="!syncing && !adjustingPrice" class="card-tools card-tools-container">
                                <input type="text" name="search" class="form-control" placeholder="Search"
                                    v-model="searchQuery" @input="searchProducts" />
                                <div class="card-tools-buttons">
                                    <button v-if="$gate.hasPermission('products_price_adjust_all')" type="button"
                                        class="btn btn-sm btn-primary" @click="adjustPrice(0, 0)">
                                        <i class="fas fa-money-check-alt"></i>
                                        Adjust All Prices
                                    </button>
                                    <button v-if="$gate.hasPermission('products_price_revert_all')" type="button"
                                        class="btn btn-sm btn-primary" @click="revertPrice(0, 0)">
                                        <i class="fas fa-undo-alt"></i>
                                        Revert All Prices
                                    </button>
                                    <button v-if="$gate.hasPermission('products_force_sync')" type="button"
                                        class="btn btn-sm btn-primary" @click="syncItems">
                                        <i class="fa fa-sync"></i>
                                        Sync Items
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Original Price</th>
                                        <th>Current Price</th>
                                        <th>Adjustment</th>
                                        <th
                                            v-if="$gate.hasPermission('products_price_revert_single') || $gate.hasPermission('products_price_adjust_single')">
                                            Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="product in products" :key="product.id" v-if="!syncing && !adjustingPrice">
                                        <td data-label="Name">{{ product.name }}</td>
                                        <td data-label="Original Price">${{ product.initialPrice }}</td>
                                        <td data-label="Current Price">${{ product.currentPrice }}</td>
                                        <td data-label="Adjustment">{{ product.increased_percent }}%</td>
                                        <td data-label="Action"
                                            v-if="$gate.hasPermission('products_price_revert_single') || $gate.hasPermission('products_price_adjust_single')">
                                            <a v-if="product.is_increased && $gate.hasPermission('products_price_revert_single')"
                                                href="#" @click="revertPrice(product.item_id, 1)">
                                                <i class="fas fa-undo-alt"></i>
                                                Revert Price
                                            </a>
                                            <a v-if="!product.is_increased && $gate.hasPermission('products_price_adjust_single')"
                                                href="#" @click="adjustPrice(product.item_id, 1)">
                                                <i class="fas fa-edit"></i>
                                                Adjust Price
                                            </a>
                                        </td>
                                    </tr>
                                    <div style="margin: 10px;" v-if="loading">Loading...</div>
                                    <div class="syncing-screen" v-if="syncing && $gate.isImpersonating()">
                                        <div class="loading-spinner"></div>
                                        <div class="loading-text">Syncing items...<br>{{ syncSubText }}</div>
                                    </div>
                                    <div class="syncing-screen" v-if="adjustingPrice && $gate.isImpersonating()">
                                        <div class="loading-spinner"></div>
                                        <div class="loading-text">{{ priceAdjustmentText }}<br>{{ priceAdjustmentSubText }}
                                        </div>
                                    </div>
                                    <div style="margin: 10px;"
                                        v-if="!loading && products.length === 0 && !syncing && !adjustingPrice">
                                        No products found.
                                        <span v-tooltip:right="'Please verify that items are synced with Clover.'">
                                            <i class="far fa-question-circle"></i>
                                        </span>
                                    </div>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                        </div>
                        <div v-if="syncing && !$gate.isImpersonating()" class="loading-screen">
                            <div class="loading-spinner"></div>
                            <div class="loading-text">Syncing items...<br>{{ syncSubText }}</div>
                        </div>
                        <div v-if="adjustingPrice && !$gate.isImpersonating()" class="loading-screen">
                            <div class="loading-spinner"></div>
                            <div class="loading-text">{{ priceAdjustmentText }}<br>{{ priceAdjustmentSubText }}</div>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="priceAdjust" tabindex="-1" role="dialog" aria-labelledby="priceAdjust"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="modal-title">
                                <h4 v-if="adjustSingle">Increase price of item</h4>
                                <h4 v-if="adjustAll">Increase price of all items</h4>
                            </div>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <label for="percentage" class="col-md-6 col-form-label">Increase Percent:</label>
                            <div class="col-md-12">
                                <input v-model="percentage" ref="percentage" id="percentage" type="number" pattern="\d*"
                                    min="0" max="100" class="form-control" @input="validateInput" @keypress="checkKeyPress">
                                <div v-if="showError" style="color: red">
                                    Value must be between 0.01 and 100.00.
                                </div>
                            </div>
                            <br>
                            <div class="col-md-12">
                                <button v-if="adjustSingle"
                                    @click="setPriceAdjustment('single', adjustProductId); resetPercentage()"
                                    class="btn btn-md btn-primary" :disabled="showError">Adjust Price</button>
                                <button v-if="adjustAll" @click="setPriceAdjustment('all'); resetPercentage()"
                                    class="btn btn-md btn-primary" :disabled="showError">Adjust All Prices</button>
                                <button class="btn btn-md btn-secondary" data-dismiss="modal" @click="resetPercentage"
                                    aria-label="Close">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import Swal from 'sweetalert2';
function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}
export default {
    data() {
        return {
            editmode: false,
            products: [],
            page: 1,
            lastLoadedOffset: 0,
            loading: false,
            syncing: false,
            syncSubText: "This may take a moment.",
            percentage: '4.00',
            showError: false,
            adjustSingle: false,
            adjustAll: false,
            adjustProductId: 0,
            adjustingPrice: false,
            searchQuery: "",
            adjustedCount: 0,
            totalCount: 0,
            priceAdjustmentText: "Undergoing Price Adjustment...",
            priceAdjustmentSubText: "This may take a few minutes.",
            checkingJobStatus: false,
        };
    },
    created() {
        this.checkJobStatusUntilComplete();
        this.loadproducts();
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.checkingJobStatus = false;
    },
    beforeDestroy() {
        this.checkingJobStatus = false;
    },
    methods: {
        handleScroll() {
            const scrollY = window.scrollY + 1;
            const visibleHeight = window.innerHeight;
            const pageHeight = document.documentElement.scrollHeight;
            const offset = this.products.length;

            if (scrollY + visibleHeight >= pageHeight && !this.loading && offset !== this.lastLoadedOffset) {
                this.lastLoadedOffset = offset;
                this.getResults(this.searchQuery);
                console.log("Loading more items...");
            }
        },
        getResults(searchQuery) {
            this.$Progress.start();
            this.loading = true;
            if (this.$gate.hasPermission('products_view') && !this.syncing && !this.adjustingPrice) {
                axios
                    .get('api/products', {
                        params: {
                            offset: this.products.length,
                            search: searchQuery,
                        }
                    })
                    .then(({ data }) => {
                        const updatedProducts = data.data.products.map(product => {
                            return {
                                ...product,
                                initialPrice: (product.initial_price / 100).toFixed(2),
                                currentPrice: (product.current_price / 100).toFixed(2)
                            };
                        });
                        if (this.products.length === 0) {
                            this.adjustedCount = data.data.adjusted_count;
                            this.totalCount = data.data.total_count;
                        }
                        this.products = [...this.products, ...updatedProducts];
                        this.loading = false;
                    });
                this.$Progress.finish();
            } else {
                this.$Progress.fail();
                this.loading = false;
            }
        },
        loadproducts(searchQuery) {
            this.getResults(searchQuery);
        },
        searchProducts: debounce(function () {
            this.products = [];
            this.lastLoadedOffset = 0;
            this.loadproducts(this.searchQuery);
        }, 300),
        syncItems() {
            this.$Progress.start();
            Swal.fire({
                title: 'Sync Items',
                text: 'Are you sure you want to sync items? This may a few minutes.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, sync items',
                cancelButtonText: 'Cancel'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.products = [];
                    this.lastLoadedOffset = 0;
                    this.syncing = true;
                    // If user confirmed, make the API request
                    axios.get('api/products/sync-all-clover-items-to-db')
                        .then((response) => {
                            if (response.data.status === 1) {
                                this.showSyncSuccess();
                            } else {
                                this.showSyncError();
                            }
                            this.loadproducts();
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(error);
                            this.showSyncError();
                            this.loadproducts();
                            this.loading = false;
                        });
                }
            });
            this.$Progress.finish();
        },
        showSyncSuccess() {
            Swal.fire({
                title: 'Sync Successful',
                text: 'All items have been synced',
                icon: 'success'
            });
            this.syncing = false;
        },
        showSyncError() {
            Swal.fire({
                title: 'Sync Failed',
                text: 'There was an error syncing items',
                icon: 'error'
            });
            this.syncing = false;
        },
        adjustPrice(productId, type) {
            if (type === 1) {
                this.adjustSingle = true;
                this.adjustAll = false;
                this.adjustProductId = productId;
            } else {
                this.adjustSingle = false;
                this.adjustAll = true;
            }
            $('#priceAdjust').modal('show');
        },
        revertPrice(productId, type) {
            if (type == 1) {
                // Swal confirm button
                Swal.fire({
                    title: 'Revert Item',
                    text: 'Are you sure you want to revert this item?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, revert item',
                    cancelButtonText: 'Cancel'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setPriceAdjustment('revert-single', productId);
                    }
                });
            } else {
                Swal.fire({
                    title: 'Revert Items',
                    text: 'Are you sure you want to revert all items? This may a few minutes.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, revert items',
                    cancelButtonText: 'Cancel'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setPriceAdjustment('revert-all', 0);
                    }
                });
            }
        },
        checkJobStatus() {
            return new Promise((resolve, reject) => {
                axios.get('api/user/job-status')
                    .then((response) => {
                        if (response.data.type === 'sync') {
                            if (response.data.status === 0) {
                                this.showSyncError();
                                this.products = [];
                                this.lastLoadedOffset = 0;
                                this.loadproducts();
                                this.loading = false;
                            } else if (response.data.status === 1) {
                                this.showSyncSuccess();
                                this.products = [];
                                this.lastLoadedOffset = 0;
                                this.loadproducts();
                                this.loading = false;
                            } else if (response.data.status === 2) {
                                this.syncing = true;
                                this.products = [];
                                this.lastLoadedOffset = 0;
                                this.syncSubText = response.data.data.progress;
                            }
                        } else if (response.data.type === 'merchant') {
                            if (response.data.status === 0) {
                                this.adjustingPrice = false;
                                reject(true);
                                this.showAdjustmentError();
                            } else if (response.data.status === 1) {
                                this.adjustingPrice = false;
                                resolve(true);
                            } else if (response.data.status === 2) {
                                this.adjustingPrice = true;
                                this.products = [];
                                this.lastLoadedOffset = 0;
                                this.priceAdjustmentText = "Adjustment in progress...";
                                this.priceAdjustmentSubText = response.data.data.item_count + "/" + response.data.data.total_items + " items adjusted";
                                resolve(false);
                            } else if (response.data.status === 3) {
                                this.adjustingPrice = true;
                                this.products = [];
                                this.lastLoadedOffset = 0;
                                this.retryFailedAdjustments(response.data.data.retryId);
                                resolve(false);
                            }
                        } else {
                            if (this.adjustingPrice == true) {
                                this.adjustingPrice = false;
                                resolve(true);
                            }

                            if (this.syncing == true) {
                                this.showSyncSuccess();
                                this.products = [];
                                this.lastLoadedOffset = 0;
                                this.loadproducts();
                                this.loading = false;
                            }
                        }


                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        async checkJobStatusUntilComplete() {
            this.checkingJobStatus = true;
            let isJobComplete = false;
            while (!isJobComplete) {
                if (this.checkingJobStatus == false) {
                    break;
                }
                try {
                    isJobComplete = await this.checkJobStatus();
                    if (!isJobComplete) {
                        await new Promise(resolve => setTimeout(resolve, 5000));
                    } else {
                        await this.showAdjustmentSuccess();
                    }
                } catch (error) {
                    console.error('Error checking job status:', error);
                }
            }
            this.checkingJobStatus = false;
        },
        async setPriceAdjustment(type, productId) {
            $('#priceAdjust').modal('hide');
            this.adjustingPrice = true;
            this.products = [];
            this.lastLoadedOffset = 0;
            this.priceAdjustmentText = "Undergoing Price Adjustment...";
            this.priceAdjustmentSubText = "This may take a few minutes.";

            try {
                const payload = {
                    type: type,
                    percentage: this.percentage
                };

                if (type === 'single' || type === 'revert-single') {
                    payload.item_id = productId;
                }

                const timeoutPromise = new Promise(resolve => setTimeout(resolve, 3000));

                const responsePromise = axios.post('api/products/adjust-price', payload);

                if (type !== 'single' && type !== 'revert-single') {
                    await Promise.race([responsePromise, timeoutPromise]);
                } else {
                    await responsePromise;
                    this.showAdjustmentSuccess();
                }

                this.checkJobStatusUntilComplete();

            } catch (error) {
                console.error(error);
                this.showAdjustmentError();
            }
        },
        async retryFailedAdjustments(retryId) {
            this.adjustingPrice = true;
            this.priceAdjustmentText = "Wrapping up, just a few moments.";
            this.priceAdjutmentSubText = "Retrying Failed Price Adjustments...";
            try {
                await axios.post('api/products/adjust-price', { retryId });
            } catch (error) {
                console.error(error);
                this.showAdjustmentError();
            }
        },
        async showAdjustmentSuccess() {
            this.adjustingPrice = false;
            this.products = [];
            this.lastLoadedOffset = 0;

            return Swal.fire({
                title: 'Price Adjusted',
                html: '<p class="adjustment-text">To see price changes on device, open the Clover Inventory App and refresh the inventory.</p>',
                icon: 'success'
            }).then(() => {
                this.products = [];
                this.loadproducts();
            });
        },
        showAdjustmentError() {
            this.adjustingPrice = false;
            this.products = [];
            this.loadproducts();
            Swal.fire({
                title: 'Price Adjustment Failed',
                text: 'There was an error adjusting the price',
                icon: 'error'
            });
        },
        setCursorToEnd(el) {
            if (typeof el.selectionStart === "number") {
                el.selectionStart = el.selectionEnd = el.value.length;
            } else if (typeof el.createTextRange !== "undefined") {
                el.focus();
                var range = el.createTextRange();
                range.collapse(false);
                range.select();
            }
        },
        validateInput() {
            if (!window.isCloverDevice && !window.AndroidInterface) {
                this.formatNum();
            }

            let num = parseFloat(this.percentage);
            if (window.isCloverDevice || window.AndroidInterface) {
                let decimalPart = this.percentage.split(".")[1];
                if (decimalPart && decimalPart.length > 2) {
                    // Don't allow more than 2 decimal places
                    this.percentage = this.percentage.slice(0, this.percentage.length - 1);
                }
            }
            if (isNaN(num) || num < 0.01 || num > 100) {
                this.showError = true;
            } else {
                this.showError = false;
            }
            // Set the cursor position after validation
            this.$nextTick(() => {
                this.setCursorToEnd(this.$refs.percentage);
            });
        },
        formatNum() {
            var num = this.percentage.replace(/\D/g, '');

            // Ensure the number isn't too large 
            num = num.length > 5 ? num.slice(0, 5) : num;

            // Pad the number with leading zeros if it's too short 
            while (num.length < 3) {
                num = '0' + num;
            }

            // Insert the decimal point 
            num = num.slice(0, num.length - 2) + '.' + num.slice(num.length - 2);

            // Remove leading zeros (but ensure there's always one before the decimal point) 
            num = num.replace(/^0+([1-9])/, '$1').replace(/^0+\./, '0.');

            this.percentage = num;

            // Set the cursor position after formatting
            this.$nextTick(() => {
                this.setCursorToEnd(this.$refs.percentage);
            });
        },
        checkKeyPress(event) {
            const keyCode = event.keyCode ? event.keyCode : event.which;
            const keyValue = String.fromCharCode(keyCode);
            if (["e", "+", "-"].includes(keyValue.toLowerCase())) {
                event.preventDefault();
            }
        },
        resetPercentage() {
            this.percentage = '4.00';
            this.showError = false;
        },
    }
}
</script>