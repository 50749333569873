<style lang="scss">
    @import '../../../../public/css/custom.css';
    .multiselect__input {
        pointer-events: auto;
    }
</style>
<script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>
<template>
    <section class="content">
      <div class="container-fluid">
          <div class="row">
  
            <div class="col-12">
            <div v-if="!$gate.hasPermission('roles_view')">
                <not-found></not-found>
            </div>
              <div class="" v-if="$gate.hasPermission('roles_view')">
                <div class="card-header">
                  <h3 class="card-title">Roles</h3>
  
                  <div class="card-tools" v-if="$gate.hasPermission('roles_add')">
                    <div class="card-tools-buttons">    
                        <button type="button" class="btn btn-sm btn-primary" @click="addRole()">
                            <i class="fa fa-plus-square"></i>
                            Add New
                        </button>      
                    </div>
                  </div>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive p-0">
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Permissions</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="role in roles" :key="role.id">
                            <td data-label="ID">{{role.id}}</td>
                            <td data-label="Name">{{role.name}}</td>
                            <td data-label="Description">{{role.description}}</td>
                            <td data-label="Permissions">
                                <span class="badge badge-info label-many" v-for="permission in rolePermissions[role.id]" v-text="permission.title" style="margin-inline: 1px; color: white;"></span>
                            </td>
                            <td data-label="Action">
                                <a v-if="$gate.hasPermission('roles_edit')" href="#" @click="editRole(role)"><i class="fa fa-edit blue"></i></a>
                                <a v-if="$gate.hasPermission('roles_delete')" href="#" @click="deleteRole(role.id)"><i class="fa fa-trash red"></i></a> 
                                <a v-if="$gate.hasPermission('roles_view')" href="#" @click="viewRole(role)"><i class="fa fa-eye"></i> </a> 
                            </td>
                        </tr>
                        <div style="margin: 10px;" v-if="loading">Loading...</div>
                        <div style="margin: 10px;" v-if="!loading && roles.length === 0">No roles found.</div>
                        </tbody>
                    </table>
                    </div>
                    <!-- /.card-body -->
                    <div class="card-footer">
                    </div>
                </div>
              <!-- /.card -->
            </div>
          </div>
        <!-- Modal -->
        <div class="modal wide-modal fade" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="roleModal" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 v-if="editingRole" class="modal-title" id="editRoleModalLabel">Edit Role</h4>
                        <h4 v-if="viewingRole" class="modal-title" id="viewRoleModalLabel">View Role</h4>
                        <h4 v-if="creatingRole" class="modal-title" id="createRoleModalLabel">Create Role</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="form-group">
                                <label for="roleName" class="col-form-label">Role Name:</label>
                                <input v-if="creatingRole" v-model="newRole.name" type="text" class="form-control" id="roleName" placeholder="Role Name">
                                <input v-if="editingRole || viewingRole" type="text" class="form-control" id="roleName" v-model="selectedRole.name" :disabled="viewingRole">
                            </div>
                            <div class="form-group">
                                <label for="roleDescription">Role Description</label>
                                <input v-if="creatingRole" v-model="newRole.description" type="text" class="form-control" id="roleDescription" placeholder="Role Description">
                                <input v-if="editingRole || viewingRole" type="text" class="form-control" id="roleDescription" v-model="selectedRole.description" :disabled="viewingRole">
                            </div>
                            <div v-if="creatingRole" class="form-group">
                                <label for="roleType">Role Type</label>
                                <select name="type" v-model="newRole.type" id="roleType" class="form-control">
                                    <option value="merchant" selected>Merchant</option>
                                    <option value="support">Support</option>
                                    <option v-if="$gate.isAdmin()" value="admin">Admin</option>
                                </select>
                            </div>
                            <div v-if="creatingRole && newRole.type === 'merchant'" class="form-group">
                                <label for="programType">Program Type</label>
                                <select name="type" v-model="newRole.program_type" id="programType" class="form-control">
                                    <option value="cdp" selected>Cash Discount Program</option>
                                    <option value="dp">Dual Pricing</option>
                                </select>
                            </div>
                            <div v-if="editingRole || viewingRole " class="form-group">
                                <label for="roleType">Role Type</label>
                                <select name="type" v-model="selectedRole.type" id="roleType" class="form-control" :disabled="viewingRole">
                                    <option value="merchant" selected>Merchant</option>
                                    <option value="support">Support</option>
                                    <option v-if="$gate.isAdmin()" value="admin">Admin</option>
                                </select>
                            </div>
                            <div v-if="(editingRole || viewingRole) && selectedRole.type === 'merchant'" class="form-group">
                                <label for="programType">Program Type</label>
                                <select name="type" v-model="selectedRole.program_type" id="programType" class="form-control" :disabled="viewingRole">
                                    <option value="cdp" selected>Cash Discount Program</option>
                                    <option value="dp">Dual Pricing</option>
                                </select>
                            </div>
                            <div v-if="(creatingRole && newRole.type === 'merchant') || ((editingRole || viewingRole) && selectedRole.type === 'merchant')" class="form-group">
                                <label for="subId" class="col-form-label">Subscription ID:</label>
                                <input v-if="creatingRole" v-model="newRole.subscription_id" type="text" class="form-control" id="subId" placeholder="Subscription ID">
                                <input v-if="editingRole || viewingRole" type="text" class="form-control" id="subId" v-model="selectedRole.subscription_id" :disabled="viewingRole">
                            </div>
                            <div class="form-group">
                                <label for="rolePermissions">Role Permissions</label>
                                <multiselect v-if="editingRole" v-model="selectedPermissions" :value="selectedPermissions" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="false" placeholder="Select Permission" label="title" track-by="title" :preselect-first="false" required>
                                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                                        <span class="multiselect__single" v-if="value.length && !isOpen">{{ values.length }} options selected</span>
                                    </template>
                                </multiselect>
                                <multiselect v-if="creatingRole" v-model="newRole.permissions" :value="newRole.permissions" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="false" placeholder="Select Permission" label="title" track-by="title" :preselect-first="false" required>
                                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                                        <span class="multiselect__single" v-if="value.length && !isOpen">{{ values.length }} options selected</span>
                                    </template>
                                </multiselect>
                                <textarea v-if="viewingRole" class="form-control" name="rolePermissions" id="rolePermissions" cols="30" rows="10" disabled>{{ viewingPermissions }}</textarea>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button v-if="editingRole" type="button" class="btn btn-primary" @click="updateRole" data-dismiss="modal">Save changes</button>
                        <button v-if="creatingRole" type="button" class="btn btn-primary" @click="createRole" data-dismiss="modal">Add Role</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
    import Swal from 'sweetalert2';
    import Multiselect from 'vue-multiselect';
    import 'vue-multiselect/dist/vue-multiselect.min.css';
    import Vue from 'vue';

    export default {
        components: {
            Multiselect
        },
        data() {
            return {
                creatingRole: false,
                editingRole: false,
                viewingRole: false,
                editmode: false,
                roles: [],
                value:[],
                rolePermissions: [],
                newRole: {
                    name: '',
                    description: '',
                    permissions: [],
                    type: 'merchant',
                    program_type: 'cdp',
                    subscription_id: ''
                },
                viewingPermissions: null,
                selectedRole: null,
                selectedPermissions: [],
                options: [],
                page: 1,
                lastLoadedOffset: 0,
                loading: false,
            };
        },
        created() {
            this.loadRoles();
            this.loadPermissionOptions();
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll);
        },
        beforeUnmount() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll() {
                const scrollY = window.scrollY + 1;
                const visibleHeight = window.innerHeight;
                const pageHeight = document.documentElement.scrollHeight;
                const offset = this.roles.length;

                if (scrollY + visibleHeight >= pageHeight && !this.loading && offset !== this.lastLoadedOffset) {
                    this.lastLoadedOffset = offset;
                    this.getResults(this.searchQuery);
                    console.log("Loading more roles...");
                }
            },
            getResults() {
                this.$Progress.start();
                this.loading = true;
                if(this.$gate.hasPermission('roles_view')){
                    axios.get('api/roles', {
                        params: {
                            offset: this.roles.length
                        }
                    })
                    .then(({ data }) => {
                        
                        this.roles = this.roles.concat(data.data);
                        this.roles.forEach(role => {
                            axios
                            .get(`api/roles/${role.id}`)
                            .then(({ data }) => {
                                this.$set(this.rolePermissions, role.id, data.data);
                            });
                        });
                        this.loading = false;
                    });
                    this.$Progress.finish();
                }
            },
            loadRoles() {
                this.roles = [];
                this.getResults();
            },
            loadPermissionOptions() {
                axios.get("api/permissions").then(response => {
                    this.options = response.data.data;
                })
                .catch(errors => {
                    this.$Progress.fail();
                });
            },
            splitJoin(theText){
                if(theText) {
                    return theText.split(',');
                }
            },
            async loadPermissionOptions() {
                let offset = 0;
                let allOptions = [];

                try {
                    while (true) {
                        const response = await axios.get("api/permissions", {
                        params: {
                            offset: offset,
                        },
                        });

                        if (response.data.data.length === 0) {
                        break;
                        }

                        allOptions = allOptions.concat(response.data.data);
                        offset += response.data.data.length;
                    }
                } catch (error) {
                    console.error("Error loading roles:", error);
                }

                this.options = allOptions;
            },
            addRole() {
                this.creatingRole = true;
                this.editingRole = false;
                this.viewingRole = false;
                this.selectedRole = null;
                this.selectedPermissions = [];
                Vue.nextTick(() => {
                    $("#roleModal").modal("show");
                });
            },
            editRole(role) {
                this.creatingRole = false;
                this.editingRole = true;
                this.viewingRole = false;
                this.selectedRole = JSON.parse(JSON.stringify(role)); // Create a deep copy of the role object to avoid modifying the original
                if(this.selectedRole.program_type == null) {
                    this.selectedRole.program_type = 'cdp';
                }
                this.selectedPermissions = this.rolePermissions[role.id] || []; // Initialize permissions as an empty array if undefined
                Vue.nextTick(() => {
                    $("#roleModal").modal("show");
                });
            },
            viewRole(role) {
                this.creatingRole = false;
                this.editingRole = false;
                this.viewingRole = true;
                this.selectedRole = JSON.parse(JSON.stringify(role)); // Create a deep copy of the role object to avoid modifying the original
                this.viewingPermissions = this.rolePermissions[role.id].map(permission => permission.title).join(', ');
                Vue.nextTick(() => {
                    $("#roleModal").modal("show");
                });
            },
            updateRole() {
                this.$Progress.start();
                if(this.$gate.hasPermission('roles_edit')){
                    if(this.selectedRole.program_type != null && this.selectedRole.type != 'merchant') {
                        this.selectedRole.program_type = null;
                    }
                    if(this.selectedRole.subscription_id != null && this.selectedRole.type != 'merchant') {
                        this.selectedRole.subscription_id = null;
                    }
                    axios.put(`api/roles/${this.selectedRole.id}`, {
                        name: this.selectedRole.name,
                        description: this.selectedRole.description,
                        permissions: this.selectedPermissions.map(permission => permission.id),
                        type: this.selectedRole.type,
                        program_type: this.selectedRole.program_type,
                        subscription_id: this.selectedRole.subscription_id
                    })
                    .then((response) => {
                        if(response.data.status == 1) {
                            Swal.fire({
                                title: 'Success!',
                                text: response.data.message,
                                icon: 'success',
                                confirmButtonText: 'Ok'
                            });
                            this.loadRoles();
                            $("#roleModal").modal("hide");
                        } else {
                            Swal.fire({
                                title: 'Error!',
                                text: response.data.message,
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            });
                        }
                    }).catch(errors => {
                        Swal.fire({
                            title: 'Error!',
                            text: 'There was an error updating the role.',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        });
                    });
                }
                this.$Progress.finish();
            },
            createRole() {
                this.$Progress.start();
                if(this.$gate.hasPermission('roles_add')){
                    if(this.newRole.program_type != null && this.newRole.type != 'merchant') {
                        this.newRole.program_type = null;
                    }
                    if(this.newRole.subscription_id != '' && this.newRole.type != 'merchant') {
                        this.newRole.subscription_id = null;
                    }
                    axios.post(`api/roles`, {
                        name: this.newRole.name,
                        description: this.newRole.description,
                        permissions: this.newRole.permissions.map(permission => permission.id),
                        type: this.newRole.type,
                        program_type: this.newRole.program_type,
                        subscription_id: this.newRole.subscription_id
                    })
                    .then((response) => {
                        if(response.data.status == 1) {
                            Swal.fire({
                                title: 'Success!',
                                text: response.data.message,
                                icon: 'success',
                                confirmButtonText: 'Ok'
                            });
                            this.newRole.name = '';
                            this.newRole.description = '';
                            this.newRole.permissions = [];
                            this.newRole.type = '';
                            this.newRole.program_type = '';
                            this.newRole.subscription_id = '';
                            this.roles = [];
                            this.loadRoles();
                            $("#roleModal").modal("hide");
                        } else {
                            Swal.fire({
                                title: 'Error!',
                                text: response.data.message,
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            });
                        }
                    }).catch(errors => {
                        Swal.fire({
                            title: 'Error!',
                            text: 'There was an error updating the role.',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        });
                    });
                }
                this.$Progress.finish();
            },
            deleteRole(id) {
                this.$Progress.start();
                if(this.$gate.hasPermission('roles_delete')){
                    Swal.fire({
                        title: 'Are you sure you want to delete this role?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        cancelButtonText: 'No, cancel',
                        }).then((result) => {
                        if (result.isConfirmed) {
                            axios.delete('api/roles/'+id)
                            .then((response) => {
                                if(response.data.status == 1) {
                                    Swal.fire({
                                        title: 'Success!',
                                        message: response.data.message,
                                        icon: 'success',
                                    });
                                    this.roles = [];
                                    this.loadRoles();
                                } else {
                                    Swal.fire({
                                        title: 'Error!',
                                        message: response.data.message,
                                        icon: 'error'
                                    });
                                }
                            }).catch(error => {
                                Swal.fire({
                                    title: 'Error!',
                                    message: 'There was an error deleting this role.',
                                    icon: 'error'
                                });
                            });
                        } else {
                            return;
                        }
                    });
                }
                this.$Progress.finish();
            }
        }
    }
</script>