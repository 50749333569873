<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div v-if="!$gate.hasPermission('uninstall_view')">
                        <not-found></not-found>
                    </div>
                    <div class="card" v-if="$gate.hasPermission('uninstall_view')">
                        <div class="card-body table-responsive p-3">
                            <h2 style="padding: 5px 0px 10px 0px;">
                                Uninstalling True Cash Discount
                            </h2>
                            <p>
                                We're sorry to see you go. To ensure a smooth uninstallation of True Cash Discount, please
                                follow the steps on this page. This will help prevent any issues with your clover items,
                                modifier groups, and tenders.
                            </p>
                            <p><strong>Uninstalling will</strong>:</p>
                            <ol>
                                <li>Reset clover items and modifier groups to original prices.</li>
                                <li>Remove the True Cash Discount tender from your device.</li>
                                <li>Disable the True Cash Discount feature.</li>
                            </ol>
                            <p v-if="$gate.hasPermission('uninstall_manual')"><strong>Choose an uninstallation
                                    option</strong>:</p>
                            <p v-if="!$gate.hasPermission('uninstall_manual')"
                                style="font-weight: bolder; font-size: 1.2em;">Please call us at <a
                                    href="javascript:void(0);">1-800-609-0788</a> to continue with uninstall.</p>
                            <div class="row" v-if="$gate.hasPermission('uninstall_manual')">
                                <div class="col-md-12">
                                    <div class="radio" @click="selectOption('support')">
                                        <label
                                            style="font-size: 1em; cursor: pointer; margin-bottom: 20px; display: flex; align-items: center;">
                                            <input type="radio" name="uninstallOption" value="support"
                                                v-model="selectedOption"
                                                style="width: 20px; height: 20px; margin-right: 10px;" />
                                            <strong>Uninstall with Support</strong>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-12" v-if="selectedOption === 'support'">
                                    <p style="font-weight: bolder; font-size: 1.2em;">Please call us at <a
                                            href="javascript:void(0);">{{ supportPhone }}</a> to continue with uninstall.
                                    </p>
                                </div>
                                <div class="col-md-12">
                                    <div class="radio" @click="selectOption('manual')">
                                        <label style="font-size: 1em; cursor: pointer; display: flex; align-items: center;">
                                            <input type="radio" name="uninstallOption" value="manual"
                                                v-model="selectedOption"
                                                style="width: 20px; height: 20px; margin-right: 10px;" />
                                            <strong>Manual Uninstall</strong>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-12" v-if="selectedOption === 'manual'">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="checkbox1"
                                            v-model="understandPriceAdjustments"
                                            style="width: 20px; height: 20px; margin-left: 0px; cursor: pointer;">
                                        <label class="form-check-label" for="checkbox1"
                                            style="margin-left: 30px; padding-top: 4px;">
                                            I understand that when I uninstall the application, it will remove all price
                                            adjustments made to my inventory items.
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="checkbox2"
                                            v-model="understandVerificationResponsibility"
                                            style="width: 20px; height: 20px; margin-left: 0px; cursor: pointer;">
                                        <label class="form-check-label" for="checkbox2"
                                            style="margin-left: 30px; padding-top: 4px;">
                                            I understand that I'm responsible for verifying the items have been reverted
                                            and that the app is fully uninstalled.
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="checkbox3"
                                            v-model="understandCloverAppStore"
                                            style="width: 20px; height: 20px; margin-left: 0px; cursor: pointer;">
                                        <label class="form-check-label" for="checkbox3"
                                            style="margin-left: 30px; padding-top: 4px;">
                                            I understand that after the uninstall is complete, I still need to go to the
                                            Clover App Store and disconnect/uninstall the app from there.
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-3">
                                Remember, following these steps is important for a seamless experience. If you need
                                assistance, feel free to contact our support team.
                            </p>
                            <div class="col-md-12" v-if="selectedOption === 'manual'">
                                <button type="button" class="btn btn-block col-md-3"
                                    :class="allCheckboxesChecked ? 'btn-danger' : 'btn-secondary'"
                                    :disabled="!allCheckboxesChecked" @click="uninstallUserConfirmation"
                                    style="padding: 10px; margin: 20px auto 10px auto;">
                                    Uninstall
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="uninstalling" class="loading-screen">
                        <div class="loading-spinner"></div>
                        <div class="loading-text">{{ uninstallText }}<br>{{ uninstallSubText }}</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import Swal from 'sweetalert2';
export default {
    data() {
        return {
            supportPhone: window.appConfig.supportPhone,
            supportEmail: window.appConfig.supportEmail,
            leaveImpersonateUrl: window.leaveImpersonateUrl,
            editmode: false,
            selectedOption: '',
            understandPriceAdjustments: false,
            understandVerificationResponsibility: false,
            understandCloverAppStore: false,
            uninstalling: false,
            uninstallText: "Uninstalling True Cash Discount",
            uninstallSubText: "This may take a few minutes. Please do not leave this page."
        };
    },
    created() {
        this.checkJobStatusUntilComplete();
    },
    mounted() {
    },
    beforeUnmount() { },
    computed: {
        allCheckboxesChecked() {
            return (
                this.understandPriceAdjustments &&
                this.understandVerificationResponsibility &&
                this.understandCloverAppStore
            );
        },
    },
    methods: {
        selectOption(option) {
            this.selectedOption = option;
            // Perform any additional logic based on the selected option
        },
        uninstallUserConfirmation() {
            Swal.fire({
                title: 'Uninstall True Cash Discount?',
                text: 'Are you sure you want uninstall? This will take some time to complete.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, uninstall',
                cancelButtonText: 'Cancel',
                customClass: {
                    confirmButton: 'swal2-confirm-red',
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.uninstallUser();
                }
            });
        },
        checkJobStatus() {
            return new Promise((resolve, reject) => {
                axios.get('api/merchant/uninstall-status')
                    .then((response) => {
                        if (response.data.status === 2) {
                            this.uninstalling = true;
                            this.uninstallText = "Uninstalling True Cash Discount";
                            this.uninstallSubText = response.data.data.progress;
                            resolve(false);
                        } else if (response.data.status === 1) {
                            this.uninstalling = false;
                            resolve(true);
                        } else if (response.data.status === 0) {
                            this.uninstalling = false;
                            resolve(true);
                            this.showUninstallError();
                        } else if (response.data.status === 4) {
                            if (this.$gate.isImpersonating()) {
                                axios.post(this.leaveImpersonateUrl)
                                    .then(() => {
                                        window.location.href = '/';
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                        window.location.href = '/';
                                    });
                            } else {
                                window.location.href = '/reactivate';
                            }
                        } else if (!window.isActive) {
                            window.location.href = '/reactivate';
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        async checkJobStatusUntilComplete() {
            this.checkingJobStatus = true;
            let isJobComplete = false;
            while (!isJobComplete) {
                try {
                    isJobComplete = await this.checkJobStatus();
                    if (!isJobComplete) {
                        await new Promise(resolve => setTimeout(resolve, 5000));
                    } else {
                        await this.showUninstallSuccess();
                    }
                } catch (error) {
                    console.error('Error checking job status:', error);
                }
            }
            this.checkingJobStatus = false;
        },
        async uninstallUser() {
            this.uninstalling = true;
            try {
                const timeoutPromise = new Promise(resolve => setTimeout(resolve, 3000));

                const responsePromise = axios.post('api/merchant/uninstall');
                await Promise.race([responsePromise, timeoutPromise]);

                this.checkJobStatusUntilComplete();

            } catch (error) {
                console.error(error);
                this.showUninstallError();
            }
        },
        showUninstallSuccess() {
            this.uninstalling = false;
            if (window.isCloverDevice && window.AndroidInterface) {
                window.AndroidInterface.deleteTenderUninstall();
            }
            Swal.fire({
                title: 'Uninstall successful',
                text: 'The app has been uninstalled. Please go to the Clover App Store and disconnect/uninstall the app from there.',
                icon: 'success'
            }).then(() => {
                if (this.$gate.isImpersonating()) {
                    axios.post(this.leaveImpersonateUrl)
                        .then(() => {
                            window.location.href = '/';
                        })
                        .catch((error) => {
                            console.error(error);
                            window.location.href = '/';
                        });
                } else {
                    window.location.href = '/reactivate';
                }
            });
        },
        showUninstallError() {
            this.uninstalling = false;
            Swal.fire({
                title: 'Uninstall failed',
                text: 'There was was an error when trying to uninstall. Please contact support at ' + this.supportPhone + '.',
                icon: 'error'
            });
        },
    },
};
</script>
  