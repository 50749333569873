<style lang="scss">
@import '../../../../public/css/custom.css';
</style>
<template>
    <section class="content">
        <div v-if="!$gate.hasPermission('support_view')">
            <not-found></not-found>
        </div>
        <!-- Support Page -->
        <div class="container-fluid">
            <div class="row">
                <div class="content col-md-12">
                    <h2>Customer Support</h2>
                    <p>If you need help, feel free to call us, email us, or submit a support
                        ticket. Also, you might find quick answers in our Frequently Asked
                        Questions below.</p>
                </div>

            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <div class="info-box">
                        <div class="info-box-content">
                            <span class="info-box-text">Phone</span>
                            <span class="info-box-number">
                                <div> {{ supportContacts.phone }}</div>
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-4">
                    <div class="info-box mb-3">

                        <div class="info-box-content">
                            <span class="info-box-text">Email</span>
                            <span class="info-box-number">
                                <div> {{ supportContacts.email }}</div>
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->

                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>

                <div class="col-12 col-sm-6 col-md-4">
                    <div class="info-box mb-3">
                        <div class="info-box-content">
                            <span class="info-box-text">Request Callback</span>
                            <a v-if="$gate.hasPermission('support_view_ticket_categories')"
                                style="position: absolute; right: 20px; top: 10px; font-size: 1.5em; color: #275065;"
                                @click="openTicketCategoryModal()">
                                <i class="fa fa-cog"></i>
                            </a>
                            <span class="info-box-number">
                                <button type="submit" class="btn btn-primary" @click="openRequestAssistanceModal">
                                    <i class="fa fa-ticket-alt"></i>
                                    Submit a Ticket
                                </button>
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
            </div>

            <!-- AGREEMENTS -->
            <div class="card-header p-1">
                <h3 class="card-title">Agreements &amp; Docs</h3>
                <div class="card-tools card-tools-container">
                    <button v-if="$gate.hasPermission('support_add_agreement')" type="button" class="btn btn-sm btn-primary"
                        @click="openAgreementModal('Add')">
                        <i class="fa fa-plus-square"></i>
                        Add Agreement
                    </button>
                </div>
            </div>
            <div class="p-0">
                <ul class="nav nav-pills agreements">
                    <p class="p-1" v-if="agreements.length === 0">No agreements found.</p>
                    <li v-for="agreement in agreements" :key="agreement.id" @click="openAgreementModal('View', agreement)"
                        class="nav-item">
                        <a class="nav-link btn btn-primary">{{ agreement.title }}</a>
                    </li>
                </ul>
            </div>

            <!-- FAQ -->
            <div class="card-header">
                <h3 class="card-title">Frequently Asked Questions</h3>
                <div class="card-tools card-tools-container">
                    <button v-if="$gate.hasPermission('support_add_faq')" type="button" class="btn btn-sm btn-primary"
                        @click="openQuestionModal()">
                        <i class="fa fa-plus-square"></i>
                        Add Question
                    </button>
                </div>
            </div>
            <div class="p-0">
                <div v-for="question in faqQuestions" :key="question.id" @click="toggleSelectedQuestion(question.id)">
                    <!-- FAQ Question Column -->
                    <div class="faq-item col-sm-12">
                        <div class="faq-flex">
                            <div class="faq-question col-sm-10">
                                <i :class="[
                                    'fas',
                                    selectedQuestion === question.id ? 'fa-chevron-down' : 'fa-chevron-right',
                                ]"></i>
                                <span v-html="question.question" :style="question.style"></span>
                            </div>

                            <!-- Action Icons -->
                            <div class="faq-actions col-sm-2"
                                v-if="$gate.hasPermission('support_edit_faq') || $gate.hasPermission('support_delete_faq')">
                                <a href="#" v-if="$gate.hasPermission('support_edit_faq')"
                                    @click="openQuestionModal(question, true)">
                                    <i class="fa fa-edit blue"></i>
                                </a>
                                <a href="#" v-if="$gate.hasPermission('support_delete_faq')"
                                    @click="deleteQuestion(question.id)">
                                    <i class="fa fa-trash red"></i>
                                </a>
                            </div>
                        </div>
                        <transition name="slide-fade">
                            <div v-if="selectedQuestion === question.id" class="faq-answer col-md-12"
                                v-html="question.answer" :style="question.style"></div>
                        </transition>
                    </div>

                </div>
            </div>
            <div class="card-footer">
                <div v-if="creatingTicket" class="loading-screen">
                    <div class="loading-spinner"></div>
                    <div class="loading-text">Creating ticket...</div>
                </div>
            </div>
        </div>

        <!-- Ticket Category Modal -->
        <div class="modal fade" id="ticketCategoryModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="ticketCategoryModalLabel">Ticket Category Settings</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <!-- Category List -->
                        <div v-for="category in categories" :key="category.id">
                            <!-- Category Cards -->
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div v-if="editingCategoryID === category.id" class="flex-grow-1">
                                            <!-- Editable Textbox -->
                                            <input type="text" v-model="editingCategoryName" placeholder="Enter category name" class="form-control">
                                        </div>
                                        <div v-else class="flex-grow-1 d-flex align-items-center" @click="expandTicketCategory(category)">
                                            <!-- Arrow and Category Name -->
                                            <i :class="{ 'fa': true, 'fa-chevron-right': expandedCategory !== category.id, 'fa-chevron-down': expandedCategory === category.id }" style="margin-right: 8px;"></i>
                                            {{ category.name }}
                                        </div>
                                        <!-- Action Icons -->
                                        <div v-if="editingCategoryID === category.id">
                                            <!-- Save and Cancel Icons -->
                                            <a href="#" @click="updateCategoryByType(category)" class="btn-icon">
                                                <i class="fa fa-check green"></i>
                                            </a>
                                            <a href="#" @click="stopEditingCategory" class="btn-icon">
                                                <i class="fa fa-times red"></i>
                                            </a>
                                        </div>
                                        <div v-else>
                                            <!-- Action icons for non-edit state -->
                                            <a v-if="$gate.hasPermission('support_add_ticket_categories')" href="#" @click.stop="showAddSubcategoryForm = true">
                                                <i class="fa fa-plus-square green"></i>
                                            </a>
                                            <a v-if="$gate.hasPermission('support_edit_ticket_categories')" href="#" @click.stop="showEditCategoryForm(category)">
                                                <i class="fa fa-edit blue"></i>
                                            </a>
                                            <a v-if="$gate.hasPermission('support_delete_ticket_categories')" href="#" @click.stop="deleteCategory(category.id)">
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Subcategory Cards -->
                            <div  v-if="expandedCategory === category.id" class="subcategory-container">
                                <div v-for="subcategory in subcategories.filter(sub => sub.parent_cat_id == category.id)" :key="subcategory.id" class="card subcategory-card">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div v-if="editingSubcategoryID === subcategory.id" class="flex-grow-1">
                                                <!-- Editable Textbox for Subcategory -->
                                                <input type="text" v-model="editingSubcategoryName" placeholder="Enter subcategory name" class="form-control">
                                            </div>
                                            <div v-else class="flex-grow-1 d-flex align-items-center">
                                                {{ subcategory.name }}
                                            </div>
                                            <!-- Action Icons -->
                                            <div v-if="editingSubcategoryID === subcategory.id">
                                                <!-- Save and Cancel Icons -->
                                                <a href="#" @click="updateCategoryByType(subcategory)" class="btn-icon">
                                                    <i class="fa fa-check green"></i>
                                                </a>
                                                <a href="#" @click="stopEditingSubcategory" class="btn-icon">
                                                    <i class="fa fa-times red"></i>
                                                </a>
                                            </div>
                                            <div v-else>
                                                <!-- Action icons for non-edit state -->
                                                <a v-if="$gate.hasPermission('support_edit_ticket_categories')" href="#" @click.stop="showEditSubcategoryForm(subcategory)">
                                                    <i class="fa fa-edit blue"></i>
                                                </a>
                                                <a v-if="$gate.hasPermission('support_delete_ticket_categories')" href="#" @click.stop="deleteSubcategory(subcategory.id)">
                                                    <i class="fa fa-trash red"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showAddSubcategoryForm" class="card subcategory-card">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="flex-grow-1">
                                                <!-- Editable Textbox -->
                                                <input type="text" v-model="newSubcategoryName" placeholder="Enter subcategory name" class="form-control">
                                            </div>
                                            <!-- Save and Cancel Icons -->
                                            <a href="#" @click="addCategoryByType(category)" class="btn-icon">
                                                <i class="fa fa-check green"></i>
                                            </a>
                                            <a href="#" @click="showAddSubcategoryForm = false" class="btn-icon">
                                                <i class="fa fa-times red"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Add Category Button -->
                        <div v-if="!showAddCategoryForm && $gate.hasPermission('support_add_ticket_categories')" class="d-flex justify-content-end" style="margin-top: 15px">
                            <button @click="showAddCategoryForm = true" class="btn btn-sm btn-primary">
                                <i class="fa fa-plus-square"></i> 
                                Add Category
                            </button>
                        </div>
                        <!-- Add Category Form -->
                        <div v-if="showAddCategoryForm" class="card">
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="flex-grow-1">
                                        <!-- Editable Textbox -->
                                        <input type="text" v-model="newCategoryName" placeholder="Enter category name" class="form-control">
                                    </div>
                                    <!-- Save and Cancel Icons -->
                                    <a href="#" @click="addCategoryByType()" class="btn-icon">
                                        <i class="fa fa-check green"></i>
                                    </a>
                                    <a href="#" @click="showAddCategoryForm = false" class="btn-icon">
                                        <i class="fa fa-times red"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Create Ticket Modal -->
        <div class="modal fade" id="createTicketModal" tabindex="-1" role="dialog" aria-labelledby="createTicketModal"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Submit a Ticket</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="submitTicket">

                            <!-- Phone number field -->
                            <div class="form-group">
                                <label for="callback-num">Callback Number</label>
                                <input type="tel" class="form-control" id="callbackNum" v-model="callbackNum"
                                    placeholder="Phone Number" @input="acceptNumber" required>
                            </div>

                            <!-- Category Selection -->
                            <div class="form-group">
                                <label for="categorySelect">Category</label>
                                <select id="categorySelect" class="form-control" @change="updateSelectedCategory" v-model="selectedCategory">
                                    <option disabled value="">Select Category</option>
                                    <option v-for="category in categories" :key="category.id" :value="category.id">
                                        {{ category.name }}
                                    </option>
                                </select>
                            </div>

                            <!-- Subcategory Selection -->
                            <div class="form-group" v-if="selectedCategory">
                                <label for="subcategorySelect">Subcategory</label>
                                <select id="subcategorySelect" class="form-control" v-model="selectedSubcategory">
                                    <option disabled value="">Select Subcategory</option>
                                    <option v-for="subcategory in filteredSubcategories" :key="subcategory.id" :value="subcategory.name">
                                        {{ subcategory.name }}
                                    </option>
                                </select>
                            </div>

                            <!-- More Info field -->
                            <div class="form-group" v-if="selectedSubcategory === 'Other'">
                                <label for="more-info">More Info</label>
                                <textarea id="moreInfo" class="form-control" rows="3"
                                    placeholder="Enter information here..." v-model="moreInfo" required></textarea>
                            </div>

                            <div class="modal-footer">
                                <button :disabled="!allowTicketFormSubmit" type="submit" class="btn btn-primary">Submit</button>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- Agreement Modal -->
        <div class="modal fade wide-modal" id="agreementModal" tabindex="-1" role="dialog"
            aria-labelledby="agreementModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="agreementModalLabel">{{ agreementModalMode === 'View' ? agreement.title
                            : agreementModalLabel }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-if="agreementModalMode === 'Add' || agreementModalMode === 'Edit'">
                        <form @submit.prevent="agreementNav">
                            <div class="form-group">
                                <label for="title">Title</label>
                                <input type="text" class="form-control" id="title" v-model="agreement.title" required>
                            </div>
                            <div class="form-group">
                                <label for="effective-date">Effective Date</label>
                                <input type="date" class="form-control" id="effective-date"
                                    v-model="agreement.effective_date" required>
                            </div>
                            <div class="form-group">
                                <label for="version">Version</label>
                                <input type="text" class="form-control" id="version" v-model="agreement.version" required>
                            </div>
                            <div class="form-group">
                                <label for="program-type">Program Type</label>
                                <select class="form-control" id="program-type" v-model="agreement.program_type" required>
                                    <option value="all" selected>All</option>
                                    <option value="cdp">Cash Discount Program</option>
                                    <option value="dp">Dual Pricing</option>
                                </select>
                            </div>
                            <!-- is required check box -->
                            <div class="form-group">
                                <label for="is-required">Is Required</label>
                                <input type="checkbox" class="form-check required-checkbox" id="is-required"
                                    v-model="agreement.is_required">
                            </div>

                            <div class="form-group">
                                <label for="edit-answer">Agreement</label>
                                <vue-editor v-model="agreement.content" id="agreement-content" rows="3"
                                    placeholder="Enter response"></vue-editor>
                            </div>
                            <button type="submit" :disabled="!isValidAgreementForm" class="btn btn-primary">Save</button>
                        </form>
                    </div>
                    <div class="modal-body" v-if="agreementModalMode === 'View'">
                        <form @submit.prevent="agreementNav">
                            <div class="agreement-content">
                                <div class="ql-editor" v-html="agreement.content"></div>
                            </div>
                            <h5 v-if="agreement.acknowledgment_date" :class="!$gate.isImpersonating() ? 'mt-3 mb-0' : ''">
                                <span style="font-weight:bolder;"><span v-if="$gate.isImpersonating()">Merchant
                                    </span>Acknowledgement Date</span>: {{ agreement.acknowledgment_date }}
                            </h5>
                            <button v-if="$gate.hasPermission('support_edit_agreement')"
                                @click="openAgreementModal('Edit', agreement)" class="btn btn-primary">Edit</button>
                            <button v-if="$gate.hasPermission('support_delete_agreement')"
                                @click="deleteAgreement(agreement.id)" class="btn btn-danger">Delete</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- Question Modal -->
        <div class="modal fade" id="questionModal" tabindex="-1" role="dialog" aria-labelledby="questionModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="questionModalLabel" v-if="questionEditMode">Edit Question</h5>
                        <h5 class="modal-title" id="questionModalLabel" v-if="!questionEditMode">Add Question</h5>

                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="questionNav">
                            <div class="form-group">
                                <label for="program-type">Program Type</label>
                                <select class="form-control" id="program-type" v-model="question.program_type" required>
                                    <option value="all" selected>All</option>
                                    <option value="cdp">Cash Discount Program</option>
                                    <option value="dp">Dual Pricing</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="edit-question">Question</label>
                                <input type="text" class="form-control" id="edit-question" v-model="question.question"
                                    required>
                            </div>
                            <div class="form-group">
                                <label for="edit-answer">Answer</label>
                                <vue-editor v-model="question.answer" id="edit-answer" rows="3"
                                    placeholder="Enter response"></vue-editor>
                            </div>
                            <button type="submit" class="btn btn-primary" v-if="questionEditMode">Update</button>
                            <button type="submit" class="btn btn-primary" v-if="!questionEditMode">Add</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style>
.nav-pills.agreements .nav-link {
    color: white !important;
    margin: 0px 10px 10px 0px;
}

.required-checkbox {
    width: 15px;
    height: 15px;
}

.fa-cog {
    cursor: pointer;
}

.card > .card-body {
    padding-top: 12px;
    padding-bottom: 12px;
}

.card {
    cursor: pointer;
    margin-bottom: 10px; /* Space between cards */
}

.subcategory-container {
    width: 100%;
}

.subcategory-card {
    cursor: default;
    background-color: #f5f5f5;
    margin-left: auto;
    width: 90%;
}

.subcategory-actions {
    float: right;
}

.btn-icon {
    margin-left: 15px; /* Space between icons */
}
</style>

<script>
import Swal from 'sweetalert2';
import { VueEditor } from 'vue2-editor';

export default {
    components: { VueEditor },
    name: "Support",
    data() {
        return {
            // Support contact information
            // ---------------------------
            supportContacts: {
                phone: window.appConfig.supportPhone,
                email: window.appConfig.supportEmail,
            },

            // Ticket and question form data
            // -----------------------------
            question: new Form({
                id: null,
                program_type: "all",
                question: "",
                answer: "",
            }),

            // Ticket options for selection
            // ----------------------------
            categories: [],
            subcategories: [],
            selectedCategory: '',
            selectedCategoryName: '',
            selectedSubcategory: '',

            // Ticket categories
            // ----------------------------
            expandedCategory: null,
            showAddCategoryForm: false,
            showAddSubcategoryForm: false,
            newCategoryName: '',
            editingCategoryID: null,
            editingCategoryName: '',
            newSubcategoryName: '',
            editingSubcategoryID: null,
            editingSubcategoryName: '',

            // User interaction states
            // -----------------------
            moreInfo: "",
            callbackNum: "",
            selectedQuestion: null,
            questionEditMode: false,
            creatingTicket: false,

            // Agreement management
            // --------------------
            agreements: [],
            agreement: new Form({
                id: null,
                title: "",
                version: "",
                effective_date: "",
                program_type: "all",
                is_required: false,
                content: "",
            }),
            agreementModalLabel: "Add Agreement",
            agreementModalMode: "Add",

            // FAQs
            faqQuestions: [],
        };
    },
    created() {
        this.getIndex();
    },
    computed: {
        isValidAgreementForm() {
            const isTitleValid = this.agreement.title && this.agreement.title.length <= 100;
            const isVersionValid = this.agreement.version && this.agreement.version.length <= 11;
            return isTitleValid && isVersionValid;
        },
        filteredSubcategories() {
            return this.subcategories.filter(sub => Number(sub.parent_cat_id) === this.selectedCategory);
        },
        allowTicketFormSubmit() {
            const isCallbackNumValid = this.callbackNum && this.callbackNum.length === 14;
            const isCategorySelected = !!this.selectedCategory;
            const isSubcategorySelected = !!this.selectedSubcategory;
            return isCallbackNumValid && isCategorySelected && isSubcategorySelected;
        }
    },
    methods: {
        // Utility Functions
        // -----------------
        showToast(icon, title) {
            Toast.fire({ icon, title });
        },
        resetModalState() {
            this.callbackNum = "";
            this.selectedCategory = "";
            this.selectedCategoryName = "";
            this.selectedSubcategory = "";
            this.moreInfo = "";
            $('#createTicketModal').off('hide.bs.modal').on('hide.bs.modal', this.resetModalState);
        },
        acceptNumber() {
            const digits = this.callbackNum.replace(/\D/g, '');
            this.callbackNum = digits.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        },
        disableScroll() {
            const body = document.body;
            const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
            body.style.overflow = 'hidden';
            body.style.paddingRight = `${scrollBarWidth}px`; // Prevent layout shifting due to scrollbar disappearance.
            body.classList.add('no-scroll'); // Apply no-scroll for fixed positioning.

            this.originalTouchMove = document.ontouchmove;
            document.ontouchmove = (e) => {
                e.preventDefault();
            };
        },
        enableScroll() {
            const body = document.body;
            body.style.overflow = '';
            body.style.paddingRight = ''; // Reset padding right.
            body.classList.remove('no-scroll'); // Remove no-scroll to re-enable scrolling.

            document.ontouchmove = this.originalTouchMove;
        },
        updateSelectedCategory() {
            const selectedCat = this.categories.find(cat => cat.id === this.selectedCategory);
            this.selectedCategoryName = selectedCat ? selectedCat.name : '';
            this.selectedSubcategory = '';
        },
        showEditCategoryForm(category) {
            this.editingCategoryID = category.id;
            this.editingCategoryName = category.name;
        },
        showEditSubcategoryForm(subcategory) {
            this.editingSubcategoryID = subcategory.id;
            this.editingSubcategoryName = subcategory.name;
        },
        stopEditingCategory() {
            this.editingCategoryID = null;
            this.editingCategoryName = '';
        },
        stopEditingSubcategory() {
            this.editingSubcategoryID = null;
            this.editingSubcategoryName = '';
        },

        // Navigation and Decision Logic
        // -----------------------------
        questionNav() {
            if (this.questionEditMode) {
                this.updateQuestion();
            } else {
                this.addQuestion();
            }
        },
        agreementNav() {
            if (this.agreementModalMode === "Add") {
                this.addAgreement();
            } else if (this.agreementModalMode === "Edit") {
                this.updateAgreement();
            }
        },

        // CRUD Operations for Questions and Agreements
        // --------------------------------------------
        async getIndex() {
            this.$Progress.start();
            try {
                const type = this.$gate.isCDP() ? "cdp" : this.$gate.isDP() ? "dp" : "all";
                const { data } = await axios.get(`api/support?type=${type}`);
                this.faqQuestions = data.data.questions;
                this.agreements = data.data.agreements;
                this.categories = data.data.supportCategories.categories;
                this.subcategories = data.data.supportCategories.subcategories;
            } catch (error) {
                console.error("Error fetching index data:", error);
            }
            this.$Progress.finish();
        },
        async getTicketPrompts() {
            this.$Progress.start();
            try {
                const { data } = await axios.get("api/ticket-prompts");
                this.faqQuestions = data.data;
            } catch (error) {
                console.error("Error fetching ticket prompts:", error);
            }
            this.$Progress.finish();
        },
        addQuestion() {
            axios.post("api/support/faq", this.question)
                .then(({ data }) => {
                    this.faqQuestions.push(data);
                    this.resetQuestion();
                    $("#questionModal").modal("hide");
                    Swal.fire('Success!', "Successfully added a new question.", 'success');
                })
                .catch(error => console.error("Error adding question:", error));
        },
        updateQuestion() {
            axios.put(`api/support/faq/${this.question.id}`, this.question)
                .then(({ data }) => {
                    const index = this.faqQuestions.findIndex(question => question.id === this.question.id);
                    Object.assign(this.faqQuestions[index], data.data); // More concise object update
                    this.resetQuestion();
                    $("#questionModal").modal("hide");
                    Swal.fire('Success!', "Successfully updated the question.", 'success');
                })
                .catch(error => console.error("Error updating question:", error));
        },
        addAgreement() {
            axios.post("api/support/agreement", this.agreement)
                .then(({ data }) => {
                    this.agreements.push(data);
                    this.resetAgreement();
                    $("#agreementModal").modal("hide");
                    Swal.fire('Success!', "Successfully added a new agreement. Agreement will only show when effective date is reached.", 'success');
                })
                .catch(error => console.error("Error adding agreement:", error));
        },
        updateAgreement() {
            axios.put(`api/support/agreement/${this.agreement.id}`, this.agreement)
                .then(({ data }) => {
                    const index = this.agreements.findIndex(agreement => agreement.id === this.agreement.id);
                    Object.assign(this.agreements[index], data.data); // More concise object update
                    this.resetAgreement();
                    $("#agreementModal").modal("hide");
                    Swal.fire('Success!', "Successfully updated the agreement.", 'success');
                })
                .catch(error => console.error("Error updating agreement:", error));
        },
        addCategoryByType(parentCategory = null) {
            const name = parentCategory ? this.newSubcategoryName : this.newCategoryName;

            if (!name.trim()) {
                Swal.fire('Error!', "Name cannot be empty.", 'error');
                return;
            }

            const categoryData = {
                parent_cat_id: parentCategory ? parentCategory.id : null,
                name: name
            };

            axios.post("/api/support/ticket-category", categoryData)
                .then(({ data }) => {
                    if (parentCategory) {
                        this.subcategories.push(data);
                        this.newSubcategoryName = '';
                        this.showAddSubcategoryForm = false;
                    } else {
                        this.categories.push(data);
                        this.newCategoryName = '';
                        this.showAddCategoryForm = false;
                    }
                    Swal.fire('Success!', `Successfully added a new ${parentCategory ? 'subcategory' : 'category'}.`, 'success');
                })
                .catch(error => {
                    console.error(`Error adding ${parentCategory ? 'subcategory' : 'category'}:`, error);
                    Swal.fire('Error!', `Failed to add a new ${parentCategory ? 'subcategory' : 'category'}.`, 'error');
                });
        },
        updateCategoryByType(itemToUpdate) {
            const newName = itemToUpdate.parent_cat_id ? this.editingSubcategoryName : this.editingCategoryName;

            if (!newName.trim()) {
                Swal.fire('Error!', `${itemToUpdate.parent_cat_id ? 'Subcategory' : 'Category'} name cannot be empty.`, 'error');
                return;
            }

            const updateData = {
                name: newName
            };

            axios.put(`/api/support/ticket-category/${itemToUpdate.id}`, updateData)
                .then(({ data }) => {
                    const index = itemToUpdate.parent_cat_id ? this.subcategories.findIndex(sub => sub.id === itemToUpdate.id) : this.categories.findIndex(cat => cat.id === itemToUpdate.id);
                    if (itemToUpdate.parent_cat_id) {
                        this.$set(this.subcategories, index, { ...this.subcategories[index], name: data.data.name });
                        this.stopEditingSubcategory();
                    } else {
                        this.$set(this.categories, index, { ...this.categories[index], name: data.data.name });
                        this.stopEditingCategory();
                    }
                    Swal.fire('Success!', `${itemToUpdate.parent_cat_id ? 'Subcategory' : 'Category'} successfully updated.`, 'success');
                })
                .catch(error => {
                    console.error(`Error updating ${itemToUpdate.parent_cat_id ? 'subcategory' : 'category'}:`, error);
                    Swal.fire('Error!', `Failed to update the ${itemToUpdate.parent_cat_id ? 'subcategory' : 'category'}.`, 'error');
                });
        },

        // Modal Operations
        // ----------------
        openQuestionModal(question, editmode = false) {
            if (editmode) {
                this.question = {
                    id: question.id,
                    program_type: question.program_type,
                    question: question.question,
                    answer: question.answer,
                };
            } else {
                this.resetQuestion();
            }
            this.questionEditMode = editmode;
            $("#questionModal").modal("show");
        },
        openAgreementModal(mode, agreement = null) {
            this.disableScroll();
            if (mode === 'Add') {
                this.agreementModalLabel = "Add Agreement";
                this.agreementModalMode = "Add";
                this.resetAgreement();
            } else {
                this.agreementModalLabel = mode + " Agreement";
                this.agreementModalMode = mode;
                this.agreement = agreement;
            }
            $("#agreementModal").modal("show").on('hidden.bs.modal', () => {
                this.enableScroll();
            });
        },
        openTicketCategoryModal() {
            $("#ticketCategoryModal").modal("show");
        },
        openRequestAssistanceModal() {
            if (!this.$gate.isMerchant() && !this.$gate.isImpersonating()) {
                this.showToast('error', 'This feature is for merchant use only!');
                return;
            }
            this.resetModalState();
            $('#createTicketModal').modal('show');
        },

        // Toggle and Submit Functions
        // ---------------------------
        toggleSelectedQuestion(questionId) {
            this.selectedQuestion = this.selectedQuestion === questionId ? null : questionId;
        },
        expandTicketCategory(category) {
            if (this.expandedCategory === category.id) {
                // If the category is already expanded, collapse it
                this.expandedCategory = null;
            } else {
                // Expand the new category
                this.expandedCategory = category.id;
            }
        },
        async submitTicket() {
            const formData = {
                category: this.selectedCategoryName,
                subcategory: this.selectedSubcategory,
                moreInfo: this.moreInfo,
                callbackNum: this.callbackNum
            };

            try {
                $("#createTicketModal").modal("hide");
                this.creatingTicket = true;
                await axios.post('/api/support/ticket', formData);
                this.resetTicketForm();
                Swal.fire('Success!', "A ticket has been successfully created. Support will contact you within the next business day.", 'success');
            } catch (error) {
                console.error("Error submitting ticket:", error);
                this.resetTicketForm(true);
            }
        },

        // Reset Functions
        // ---------------
        resetTicketForm(failed = false) {
            this.creatingTicket = false;

            if (failed) {
                Swal.fire('Ticket Failed to Send', 'There was an error sending this ticket, please try again later.', 'error');
            }
        },
        resetQuestion() {
            this.question = {
                id: null,
                program_type: "all",
                question: "",
                answer: "",
            };
        },
        resetAgreement() {
            this.agreement = {
                id: null,
                title: "",
                version: "",
                effective_date: "",
                program_type: "all",
                is_required: false,
                content: "",
            };
        },

        // Delete Operations
        // -----------------
        deleteQuestion(questionId) {
            this.deleteResource('question', questionId);
        },
        deleteAgreement(agreementId) {
            this.deleteResource('agreement', agreementId);
        },
        deleteCategory(categoryId) {
            this.deleteResource('category', categoryId);
        },
        deleteSubcategory(subcategoryId) {
            this.deleteResource('subcategory', subcategoryId);
        },
        deleteResource(resourceType, resourceId) {
            const resourceMap = {
                question: { path: 'faq', array: this.faqQuestions, modal: '' },
                agreement: { path: 'agreement', array: this.agreements, modal: '#agreementModal' },
                category: { path: 'ticket-category', array: this.categories, modal: '' },
                subcategory: { path: 'ticket-category', array: this.subcategories, modal: '' }
            };
            const resource = resourceMap[resourceType];

            Swal.fire({
                title: 'Are you sure?',
                text: `You will not be able to recover this ${resourceType}!`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    if (resource.modal) $(resource.modal).modal("hide");
                    axios.delete(`api/support/${resource.path}/${resourceId}`)
                        .then(() => {
                            const index = resource.array.findIndex(item => item.id === resourceId);
                            resource.array.splice(index, 1);
                            Swal.fire('Success!', `The ${resourceType} has been deleted.`, 'success');
                        })
                        .catch(error => console.error(`Error deleting ${resourceType}:`, error));
                }
            });
        },
    }
};

</script>