<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row justify-content-center align-items-center full-height">
                <div class="col-md-12">
                    <div class="card col-lg-6 col-md-9 col-sm-12" style="margin: 20px auto;">
                        <div class="card-body" style="padding: 0px;">
                            <div class="tab-content boarding-content">
                                <h1>Welcome to {{ appName }}</h1>
                                <p>Please follow the steps below to complete your setup.</p>
                                <transition name="fade" mode="out-in">
                                    <div :key="activeTab">
                                        <div v-show="activeTab === 'industry'">
                                            <img class="boarding-images" src="/images/boarding/choose_industry.svg"
                                                alt="Choose Industry">
                                            <h2 style="margin: 10px auto;">Choose your Industry</h2>
                                            <p class="col-md-11 col-sm-12" style="margin: 10px auto;">What industry does your business
                                                operate
                                                in? This will help us understand the tools you need to run your business.
                                            </p>
                                            <div class="col-lg-6 col-md-10 col-sm-12" style="margin: 0 auto;">
                                                <select v-model="selectedIndustry" class="form-control">
                                                    <option disabled value="">Please select an industry</option>
                                                    <option v-for="industry in industryTypes" :key="industry.id"
                                                        :value="industry.id">
                                                        {{ industry.title }}
                                                    </option>
                                                    <option value="0">Other</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div v-show="activeTab === 'priceIncrease'">
                                            <img class="boarding-images" src="/images/boarding/price_increase.svg"
                                                alt="Price Increase">
                                            <h2 style="margin: 10px auto;">Set your Price Increase (%)</h2>
                                            <p class="col-md-11 col-sm-12" style="margin: 10px auto;">This is the price increase used
                                                for ALL of your items. <br><em>Tip: If you do not want to increase prices at this time, set the value to 0.00.</em></p>
                                            <div class="col-lg-6 col-md-10 col-sm-12" style="margin: 0 auto;">
                                                <input v-model="priceIncrease" ref="PriceIncrease" id="priceIncrease"
                                                    type="number" pattern="\d*" min="0" max="100" step="0.01" class="form-control"
                                                    @input="validateAndFormat('priceIncrease', 'PriceIncrease')"
                                                    @keypress="checkKeyPress">
                                                <div v-if="showErrorPriceIncrease"
                                                    style="color: red; margin-top: 10px; margin-bottom: -10px;">
                                                    Value must be between 0.00 and 100.00.
                                                </div>
                                            </div>
                                        </div>

                                        <div v-show="activeTab === 'discountRate' && this.$gate.isCDP()">
                                            <img class="boarding-images" src="/images/boarding/discount_rate.svg"
                                                alt="Discount Rate">
                                            <h2 style="margin: 10px auto;">Set your Cash Discount Rate (%)</h2>
                                            <p class="col-md-11 col-sm-12" style="margin: 10px auto;">This is the discount you offer
                                                your cash-paying customers.</p>
                                            <div class="col-lg-6 col-md-10 col-sm-12" style="margin: 0 auto;">
                                                <input v-model="discountRate" ref="DiscountRate" id="discountRate"
                                                    type="number" pattern="\d*" min="0" max="100" step="0.01" class="form-control"
                                                    @input="validateAndFormat('discountRate', 'DiscountRate')"
                                                    @keypress="checkKeyPress">
                                                <div v-if="showErrorDiscountRate"
                                                    style="color: red; margin-top: 10px; margin-bottom: -10px;">
                                                    Value must be between 0.00 and 100.00.
                                                </div>
                                            </div>
                                        </div>

                                        <div v-show="activeTab === 'processingRate'">
                                            <img class="boarding-images" src="/images/boarding/credit_card_rate.svg"
                                                alt="Credit Card Rate">
                                            <h2 style="margin: 10px auto;">Set your Effective Rate (%)</h2>
                                            <p class="col-md-11 col-sm-12" style="margin: 10px auto;">This is the credit card
                                                processing rate given to you by your provider. <br><em>Tip: Setting this will allow you to see your Fees Paid on the Dashboard.</em></p>
                                            <div class="col-lg-6 col-md-10 col-sm-12" style="margin: 0 auto;">
                                                <input v-model="processingRate" ref="ProcessingRate" id="processingRate"
                                                    type="number" pattern="\d*" min="0" max="100" step="0.01" class="form-control"
                                                    @input="validateAndFormat('processingRate', 'ProcessingRate')"
                                                    @keypress="checkKeyPress">
                                                <div v-if="showErrorProcessingRate"
                                                    style="color: red; margin-top: 10px; margin-bottom: -10px;">
                                                    Value must be between 0.00 and 100.00.
                                                </div>
                                            </div>
                                        </div>

                                        <div v-show="activeTab === 'complete'">
                                            <img class="boarding-images" src="/images/boarding/complete.svg" alt="Complete">
                                            <h2 style="margin: 10px auto;">Congratulations</h2>
                                            <p class="col-md-11 col-sm-12" style="margin: 10px auto;" v-if="shouldIncreasePrices">Your settings have been saved. To begin increasing the prices of your inventory, please click the "Apply Increase" button below.</p>
                                            <p class="col-md-11 col-sm-12" style="margin: 10px auto;" v-if="!shouldIncreasePrices">Your settings have been saved. Please click the "Continue" button below to go to your Dashboard.</p>
                                        </div>
                                    </div>
                                </transition>
                                <div class="navigation-buttons">
                                    <button @click="move(-1)" class="btn btn-sm btn-secondary"
                                        :disabled="activeTabIndex === 0 || disableNext">Back</button>
                                    <button @click="move(1)" class="btn btn-sm btn-primary"
                                        :disabled="activeTabIndex === lastTab || disableNext || selectedIndustry == ''"
                                        v-show="activeTabIndex < lastTab && !shouldShowSkipButton">Next</button>
                                    <button @click="confirmMove()" :disabled="disableNext" class="btn btn-sm btn-primary" v-show="shouldShowSkipButton">Skip</button>
                                    <button @click="finalizeBoarding()" class="btn btn-sm btn-primary"
                                        v-show="activeTabIndex === lastTab && shouldIncreasePrices">Apply Increase</button>
                                        <button @click="finalizeBoarding()" class="btn btn-sm btn-primary"
                                        v-show="activeTabIndex === lastTab && !shouldIncreasePrices">Continue</button>
                                </div>

                                <div class="dots">
                                    <span v-for="(tab, index) in tabs" :key="index"
                                        :class="{ active: activeTabIndex === index }"></span>
                                </div>
                                <div class="support-footer">
                                    <p>Having troubles? Call us at <a>{{ supportPhone }}</a> or email us at <a>{{ supportEmail }}</a>.</p>
                                </div>

                            </div>
                            <!-- /.tab-content -->
                            <div v-if="adjustingPrice" class="loading-screen">
                                <div class="loading-spinner"></div>
                                <div class="loading-text">{{ priceAdjustmentText }}<br>{{ priceAdjustmentSubText }}</div>
                            </div>
                        </div><!-- /.card-body -->
                    </div>
                    <!-- /.nav-tabs-custom -->
                </div>
                <!-- end tabs -->
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            activeTab: 'industry',
            activeTabIndex: 0,
            lastTab: 4,
            tabs: ['industry', 'priceIncrease', 'discountRate', 'processingRate', 'complete'],
            selectedIndustry: '',
            priceIncrease: '4.00',
            showErrorPriceIncrease: false,
            discountRate: '3.85',
            processingRate: '0.00',
            showErrorProcessingRate: false,
            showErrorDiscountRate: false,
            appName: window.appConfig.appName,
            industryTypes: [],
            disableNext: false,
            priceAdjustmentText: "Undergoing Price Adjustment...",
            priceAdjustmentSubText: "This may take a few minutes.",
            adjustingPrice: false,
            supportPhone: window.appConfig.supportPhone,
            supportEmail: window.appConfig.supportEmail,
            checkingJobStatus: false
        };
    },
    created() {
        if(this.$gate.isDP()) {
            this.tabs = ['industry', 'priceIncrease', 'processingRate', 'complete'];
            this.lastTab = 3;
        }
        this.getIndustryTypes();
        this.getCurrentBoarding();
        this.checkJobStatusUntilComplete();
    },
    computed: {
        shouldShowSkipButton() {
            if (this.activeTab === 'priceIncrease' && this.priceIncrease == 0) {
                return true;
            }
            if (this.activeTab === 'discountRate' && this.discountRate == 0) {
                return true;
            }
            if (this.activeTab === 'processingRate' && this.processingRate == 0) {
                return true;
            }
            return false;
        },
        shouldIncreasePrices() {
            if(this.priceIncrease == 0) {
                return false;
            } else {
                return true;
            }
        },
    },
    methods: {
        confirmMove() {
            Swal.fire({
                title: 'Are you sure?',
                text: "You will have to manually change this later.",
                icon: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonColor: '#275065',
                cancelButtonColor: 'gray',
                confirmButtonText: 'Skip'
            }).then((result) => {
                if (result.value) {
                    this.move(1);
                }
            });
        },
        move(step) {
            this.activeTabIndex += step;
            this.activeTab = this.tabs[this.activeTabIndex];
            if(step === 1) {
                this.updateCurrentBoarding();
            }
        },
        getCurrentBoarding() {
            this.$Progress.start();
            axios.get("api/boarding")
                .then(({ data }) => {
                    if (data.data.boarding) {
                        this.selectedIndustry = data.data.boarding.industry_type_id;
                        this.priceIncrease = data.data.boarding.price_increase;
                        this.discountRate = data.data.boarding.discount_rate;
                        this.processingRate = data.data.boarding.processing_rate;
                    }
                    if(data.data.step) {
                        this.activeTabIndex = parseInt(data.data.step - 1);
                        this.activeTab = this.tabs[this.activeTabIndex];
                    }
                });
            this.$Progress.finish();
        },
        updateCurrentBoarding() {
            this.$Progress.start();
            axios.put("api/boarding", {
                industry_type_id: this.selectedIndustry,
                price_increase: this.priceIncrease,
                discount_rate: this.discountRate,
                processing_rate: this.processingRate,
                step: this.activeTabIndex + 1
            });
            this.$Progress.finish();
        },
        getIndustryTypes() {
            this.$Progress.start();
            axios.get("api/boarding/industry-types")
                .then(({ data }) => {
                    this.industryTypes = data.data;
                });
            this.$Progress.finish();
        },
        finalizeBoarding() {
            this.$Progress.start();
            if(this.priceIncrease == 0) {
                axios.put("api/boarding", {
                    industry_type_id: this.selectedIndustry,
                    price_increase: this.priceIncrease,
                    discount_rate: this.discountRate,
                    processing_rate: this.processingRate,
                    step: 0
                }).then(() => {
                    window.location.href = '/dashboard';
                });
            } else {
                // Confirm swal
                Swal.fire({
                    title: 'Are you sure?',
                    text: "This will increase the prices of all your items by " + this.priceIncrease + "%. Note: This may take a few minutes.",
                    icon: 'warning',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonColor: '#275065',
                    cancelButtonColor: 'gray',
                    confirmButtonText: 'Yes!'
                }).then((result) => {
                    if (result.value) {
                        this.increasePrices();
                    }
                });
            }
            this.$Progress.finish();
        },
        checkJobStatus() {
            return new Promise((resolve, reject) => {
                axios.get('api/user/job-status')
                    .then((response) => {
                       if (response.data.type === 'merchant') {
                            if (response.data.status === 0) {
                                this.adjustingPrice = false;
                                reject(true);
                                this.showAdjustmentError();
                            } else if (response.data.status === 1) {
                                this.adjustingPrice = false;
                                resolve(true);
                            } else if (response.data.status === 2) {
                                this.adjustingPrice = true;
                                this.priceAdjustmentText = "Adjustment in progress...";
                                this.priceAdjustmentSubText = response.data.data.item_count + "/" + response.data.data.total_items + " items adjusted";
                                resolve(false);
                            } else if (response.data.status === 3) {
                                this.adjustingPrice = true;
                                this.retryFailedAdjustments(response.data.data.retryId);
                                resolve(false);
                            }
                        } else {
                            if (this.adjustingPrice == true) {
                                this.adjustingPrice = false;
                                resolve(true);
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        async checkJobStatusUntilComplete() {
            this.checkingJobStatus = true;
            let isJobComplete = false;
            while (!isJobComplete) {
                if (this.checkingJobStatus == false) {
                    break;
                }
                try {
                    isJobComplete = await this.checkJobStatus();
                    if (!isJobComplete) {
                        await new Promise(resolve => setTimeout(resolve, 5000));
                    } else {
                        await this.showAdjustmentSuccess();
                    }
                } catch (error) {
                    console.error('Error checking job status:', error);
                }
            }
            this.checkingJobStatus = false;
        },
        async increasePrices() {
            this.adjustingPrice = true;
            try {
                const payload = {
                    percentage: this.priceIncrease
                };
                const timeoutPromise = new Promise(resolve => setTimeout(resolve, 3000));

                const responsePromise = axios.post('api/boarding/adjust-prices', payload);

                await Promise.race([responsePromise, timeoutPromise]);

                this.checkJobStatusUntilComplete();

            } catch (error) {
                console.error(error);
                this.showAdjustmentError();
            }
        },
        async showAdjustmentSuccess() {
            this.adjustingPrice = false;
            axios.put("api/boarding", {
                    industry_type_id: this.selectedIndustry,
                    price_increase: this.priceIncrease,
                    discount_rate: this.discountRate,
                    processing_rate: this.processingRate,
                    step: 0
            });
            return Swal.fire({
                title: 'Price Adjusted',
                html: '<p class="adjustment-text">To see price changes on device, open the Clover Inventory App and refresh the inventory.</p>',
                icon: 'success'
            }).then(() => {
                window.location.href = '/dashboard';
            });
        },
        showAdjustmentError() {
            this.adjustingPrice = false;
            Swal.fire({
                title: 'Price Adjustment Failed',
                text: 'There was an error adjusting the price',
                icon: 'error'
            });
        },
        setCursorToEnd(el) {
            if (typeof el.selectionStart === "number") {
                el.selectionStart = el.selectionEnd = el.value.length;
            } else if (typeof el.createTextRange !== "undefined") {
                el.focus();
                var range = el.createTextRange();
                range.collapse(false);
                range.select();
            }
        },
        validateAndFormat(formField, inputRef) {
            if (!window.isCloverDevice && !window.AndroidInterface) {
                this.formatNum(formField, inputRef);
            }

            let num = parseFloat(this[formField]);
            if (window.isCloverDevice || window.AndroidInterface) {
                let decimalPart = this[formField].split('.')[1];
                if (decimalPart && decimalPart.length > 2) {
                    this[formField] = this[formField].slice(0, -1);
                }
            }
            if (isNaN(num) || num < 0.00 || num > 100) {
                this['showError' + inputRef] = true;
                this.disableNext = true;
            } else {
                this['showError' + inputRef] = false;
                this.disableNext = false;
            }
            this.$nextTick(() => {
                this.setCursorToEnd(this.$refs[inputRef]);
            });
        },
        formatNum(formField, inputRef) {
            var num = this[formField].replace(/\D/g, '');

            // Ensure the number isn't too large 
            num = num.length > 5 ? num.slice(0, 5) : num;

            // Pad the number with leading zeros if it's too short 
            while (num.length < 3) {
                num = '0' + num;
            }

            // Insert the decimal point 
            num = num.slice(0, num.length - 2) + '.' + num.slice(num.length - 2);

            // Remove leading zeros (but ensure there's always one before the decimal point) 
            num = num.replace(/^0+([1-9])/, '$1').replace(/^0+\./, '0.');

            this[formField] = num;
            this.$nextTick(() => {
                this.setCursorToEnd(this.$refs[inputRef]);
            });
        },
        checkKeyPress(event) {
            const keyCode = event.keyCode ? event.keyCode : event.which;
            const keyValue = String.fromCharCode(keyCode);
            if (["e", "+", "-"].includes(keyValue.toLowerCase())) {
                event.preventDefault();
            }
        }
    },
};
</script>